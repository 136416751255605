.module-cover {
    padding-top: 0;
    position: relative;
    height: 540px;

    &.small {
        padding-top: 100px;
        background-color: #072542;
        height: 380px;

        .background {
            display: none;
        }
    }

    .background {
        @include full-absolute;
        height: 100%;
        width: 100vw;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:after {
            content: "";
            @include full-absolute;
            background: rgb(0,25,49);
            background: linear-gradient(180deg, rgba(0,25,49,1) 0%, rgba(0,25,49,0) 100%);
            z-index: 1;
            opacity: 0.8;
        }
    }

    .module-content,
    .grid,
    .row,
    .column-10 {
        height: 100%;
        z-index: 2;
    }

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        .title {
            position: relative;
        }

        .subtitle {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            color: white;
            @include apercu-pro-light(40px, 80px);
            transform: translate(0, 100%);
        }
    }

    .metas {
        position: absolute;
        left: 0;
        bottom: -60px;
        z-index: 3;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .cover-logo {
        height: 170px;
        width: 160px;
        background-color: $brown;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 12px 24px rgba(0, 25, 39, 0.12);

        img {
            object-fit: contain;
            width: 60%;
            height: 60%;
        }

        body.wendel-lab & {
            background-color: white;

            img {
                width: 80%;
                height: 80%;
            }
        }
    }

    .video-thumbnail {
        width: 292px;
        height: 170px;
    }

    @media screen and (max-width: $tablet) {

        h1 {
            font-size: 90px;
            line-height: 90px;
        }
    }

    @media screen and (max-width: $mobile) {
        height: 420px;

        &.small {
            height: 260px;
        }

        h1 {
            font-size: 48px;
            line-height: 48px;
        }

        .cover-logo {
            width: 116px;
            height: 116px;
        }

        .metas {
            bottom: -50px;
            //display: none;
        }
    }
}