.module-slider {

    .module-content {

        .r-1 {

            .swiper-container {
                height: 650px;
                background-color: $grey;

                .swiper-slide {
                    position: relative;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .legend {
                        opacity: 0;
                        position: absolute;
                        bottom: 20px;
                        max-width: 400px;
                        left: 5%;
                        background-color: rgba(0, 25, 49, 0.5);
                        color: white;
                        height: auto;
                        padding: 20px;
                        z-index: 2;
                        font-size: 16px;
                        line-height: 22px;
                        transition: opacity 0.6s $easeOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            .legend {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $tablet) {
        .module-content .r-1 .swiper-container {
            height: 550px;
        }
    }

    @media screen and (max-width: $tablet) {
        .module-content .r-1 .swiper-arrows {
            margin-bottom: 20px;
        }

        .module-content .r-1 .swiper-container {
            height: 300px;
        }
    }
}