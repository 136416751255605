h1 {
    @include austin-light(82px);
    line-height: 1;
    color: white;
}

h2 {
    @include austin-light(60px);
    line-height: 1.1;
    color: $dark-blue;

    &.white {
        color: white;
    }
}

hr {
    background-color: $dark-blue;
    opacity: 0.1;
    margin: 60px 0;
}

.text-content {
    @include apercu-pro-regular(18px);
    line-height: 1.77;
    color: $text-color;
    margin-top: 40px;

    &:first-child {
        margin-top: 0;
    }

    h1 {
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        @include apercu-pro-light(40px, 50px);
        color: $dark-blue;
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        margin-top: 40px;
        @include apercu-pro-light(26px, 30px);
        color: $dark-blue;

        &:first-child {
            margin-top: 0;
        }
    }

    h5 {
        @include apercu-pro-bold(12px, 18px, 0.4em);
        color: $dark-blue;
        margin-top: 40px;
        text-transform: uppercase;

        &:first-child {
            margin-top: 0;
        }
    }

    h6 {
        font-size: 13px;
        line-height: 22px;
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul,
    ol,
    p {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        padding-left: 20px;

        li {
            position: relative;
            margin-top: 10px;

            &:before {
                position: absolute;
                top: 12px;
                left: 0;
                border-radius: 50%;
                content: "";
                background-color: $text-color;
                width: 4px;
                height: 4px;
                transform: translate(-16px, 0);
            }
        }
    }

    ol {
        padding-left: 30px;
        list-style-type: decimal;
    }

    strong {
        @include apercu-pro-bold();
        font-weight: normal;
    }

    i,
    em {
        font-style: italic;
    }

    img,
    video,
    iframe,
    embed {
        margin: 40px auto;
        max-width: 100%;
        height: auto;
        width: auto;

        &:first-child {
            margin-top: 0;
        }
    }

    blockquote {
        margin: 60px 0;
        position: relative;

        &:before {
            position: absolute;
            top: -30px;
            left: 0;
            content: "";
            width: 20px;
            height: 13px;
            background: url('../images/common/quote-open.png') no-repeat center center;
            background-size: 100% 100%;
        }

        &:after {
            position: absolute;
            bottom: -20px;
            right: 0;
            content: "";
            width: 16px;
            height: 11px;
            background: url('../images/common/quote-close.png') no-repeat center center;
            background-size: 100% 100%;
        }

        p {
            @include apercu-pro-light(26px, 40px);
            color: $dark-blue;
        }

        + p {
            margin-top: 20px;
        }
    }

    a {
        color: $brown;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color .3s $easeOutQuart;
        text-decoration-thickness: .04em!important;
        text-underline-offset: .1em!important;

        &:hover {
            text-decoration-color: inherit;
        }
    }

    li > u {
        text-decoration: none;
    }

    &.documents {

        .text-content__inner {
            position: relative;
            overflow: hidden;
            height: 0;
        }

        .see-more {
            margin-top: 50px;

            &.open {
                .open {
                    display: none;
                }

                .close {
                    display: block;
                }

                svg {
                    transform: rotate(-180deg);
                }

                a {
                    html.desktop & {
                        &:hover {
                            svg {
                                transform: rotate(-180deg) translate(0, 2px);
                            }
                        }
                    }
                }
            }

            a {
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;

                html.desktop & {
                    &:hover {
                        svg {
                            transform: translate(0, 2px);
                        }
                    }
                }
            }

            .text {
                @include apercu-pro-regular(16px, 16px);
            }

            .close {
                display: none;
            }

            svg {
                stroke: $brown;
                width: 12px;
                margin-left: 12px;
                transition: transform 0.3s $easeOutQuart;
            }
        }

        ul {
            padding-left: 0;

            li {
                margin-top: 0;
                border-top: 1px solid rgba(0, 25, 49, 0.08);
                padding: 20px 0;

                &:last-child {
                    border-bottom: 1px solid rgba(0, 25, 49, 0.08);
                }

                &:before {
                    display: none;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    text-decoration-color: transparent !important;

                    .icon-pdf {
                        flex: 0 0 auto;
                        display: flex;
                        width: 30px;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: 1px solid $brown;
                        padding-left: 2px;
                        padding-bottom: 2px;
                        transition: background-color 0.6s $easeOutQuart;

                        svg {
                            width: 15px;
                            height: 15px;
                            fill: $brown;
                            transition: fill 0.6s $easeOutQuart;
                        }
                    }

                    .icon-download {
                        flex: 0 0 auto;
                        margin-left: auto;

                        svg {
                            width: 18px;
                            height: 16px;
                            fill: $dark-blue;
                            transition: fill 0.6s $easeOutQuart;
                        }
                    }

                    .metas {
                        margin-left: 20px;
                        padding-right: 40px;

                        .date {
                            @include apercu-pro-bold(12px, 12px);
                            color: $dark-blue;
                            opacity: 0.4;
                            transition: color 0.6s $easeOutQuart;
                        }

                        .name {
                            @include apercu-pro-bold(18px, 32px);
                            color: $text-color;
                            transition: color 0.6s $easeOutQuart;
                        }
                    }

                    html.desktop & {

                        &:hover {

                            .icon-pdf {
                                background-color: $brown;

                                svg {
                                    fill: white;
                                }
                            }

                            .metas {
                                .date {
                                    color: $brown;
                                }

                                .name {
                                    color: $brown;
                                }
                            }

                            .icon-download {
                                svg {
                                    fill: $brown;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /*
 * Default WP Alignment Classes
 *****************************************************************************/

    .aligncenter,.alignleft,.alignright {
        display: block;
        padding: 0;
    }

    .aligncenter {
        float: none;
        margin: .5em auto 1em;
    }

    .alignright {
        float: right;
        margin: .5em 0 1em 1em;
    }

    .alignleft {
        float: left;
        margin: .5em 1em 1em 0;
    }

    .wp-caption {
        padding: 5px 0;
        border: 1px solid #555;
        background: #444;
        text-align: center;
    }

    .wp-caption img {
        display: inline;
    }

    .wp-caption p.wp-caption-text {
        margin: 5px 0 0;
        padding: 0;
        text-align: center;
        font-size: 75%;
        font-weight: 100;
        font-style: italic;
        color: #ddd;
    }
}

table {
    margin-top: 40px;
    width: 100% !important;

    &:first-child {
        margin-top: 0;
    }

    thead {
        background-color: $dark-blue;
        color: white;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background: darken($grey, 10%);
            }

            &:nth-child(even) {
                background: darken($grey, 5%);
            }
        }
    }

    td {
        padding: 10px 20px;
    }
}

@media screen and (max-width: $mobile) {

    h2 {
        font-size: 32px;
        line-height: 44px;
    }

    .text-content {
        font-size: 16px;

        h3 {
            font-size: 30px;
            line-height: 40px;
        }

        blockquote {

            p {
                font-size: 22px;
                line-height: 32px;
            }
        }

        &.documents {

            ul {

                li {

                    a {

                        .icon-pdf {
                            display: none;
                        }

                        .metas {
                            margin-left: 0;

                            .name {
                                margin-top: 8px;
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }

    tr,
    td {
        display: block;
        height: auto !important;
    }

}