.module-companies {

    .module-content {
        margin-top: 30px;

        .fade-right {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 200px;
            height: 320px;
            z-index: 3;
            pointer-events: none;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
        }

        .r-1 {

            .title {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .content {
                margin-top: 14px;
                @include set-grid-prop(margin-left, 3, 2);
            }

            .w-lab {
                margin-top: 30px;
                border: 1px solid #EBEDEF;
                background-color: white;
                border-radius: 6px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 0 0 auto;
                        width: 35%;

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }

                    .description {
                        padding: 20px 20px 20px 30px;
                        border-left: 1px solid #EBEDEF;
                        @include apercu-pro-regular(16px, 24px);

                        .button {
                            margin-top: 20px;
                        }
                    }

                    html.desktop & {
                        &:hover {
                            .button {
                                svg {
                                    transform: translate(4px, 0);
                                }
                            }
                        }
                    }
                }
            }
        }

        .r-2 {
            margin-top: 20px;

            .column-9 {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .swiper-arrows {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .swiper-container {
                overflow: visible !important;
            }

            .swiper-slide {
                position: relative;
                background-color: white;
                height: 300px;
                box-shadow: 0 12px 25px rgba(9, 25, 49, 0.12);

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                .swiper-slide-content {
                    height: 100%;

                    .logo {
                        height: calc(100% - 120px);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            display: block;
                            max-width: 80%;
                            max-height: 80%;
                        }
                    }

                    .percent {
                        height: 120px;
                        border-top: 1px solid rgba(0, 25, 49, 0.08);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $dark-blue;
                        padding: 0 20px;
                        text-align: center;

                        .value {
                            @include apercu-pro-regular(16px, 24px);
                        }
                    }
                }

                .swiper-slide-over {
                    opacity: 0;
                    @include full-absolute;
                    background-color: white;
                    z-index: 2;
                    transition: opacity 0.6s $easeOutQuart;

                    .description {
                        height: calc(100% - 150px);
                        padding: 8%;
                        @include apercu-pro-light(40px);
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .investissement {
                        border-top: 1px solid rgba(0, 25, 49, 0.08);
                        height: 60px;
                        padding-left: 8%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        @include apercu-pro-bold(16px, 24px);
                        color: $dark-blue;
                    }

                    .container-button {
                        background-color: $brown;
                        height: 90px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding-left: 8%;
                    }
                }

                html.desktop & {

                    &:hover {
                        .swiper-slide-over {
                            opacity: 1;

                            .button {

                                svg {
                                    transform: translate(4px, 0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .module-content {

            .fade-right {
                display: none;
            }

            .r-1 {

                .content {
                    margin-top: 40px;
                }

                .w-lab {

                    a {
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding: 20px;

                        .icon {
                            padding: 0;
                            width: 128px;
                            height: auto;
                        }

                        .description {
                            padding: 0;
                            margin-left: 0;
                            margin-top: 20px;
                            font-size: 14px;
                            border-left: none;
                        }
                    }
                }
            }

            .r-2 {
                margin-top: 40px;

                .glide__arrows {
                    display: none;
                }

                .glide__track {

                    .swiper-slides {

                        .swiper-slide {
                            height: 225px;

                            .swiper-slide-content {

                                .logo {
                                    height: calc(100% - 60px);
                                }

                                .percent {
                                    height: 60px;

                                    .value {
                                        font-size: 36px;
                                    }

                                    .indice {
                                        font-size: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}