#footer {

    .section-links {
        padding-top: 50px;
        background-color: $grey;

        .r-1 {
            display: flex;
            align-items: flex-end;

            [class*='column'] {
                float: none;
            }

            .c-2 {
                margin-left: auto;

                ul {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    li {
                        margin-left: 50px;

                        &:first-child {
                            margin-left: 0;
                        }

                        a {
                            display: block;

                            svg {
                                width: 24px;
                                height: auto;
                                fill: $brown;
                                transition: fill 0.6s $easeOutQuart;
                            }

                            html.desktop & {
                                &:hover {
                                    svg {
                                        fill: darken($brown, 5%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .r-2 {
            margin-top: 80px;
            padding-bottom: 60px;

            .column-3,
            .column-2 {
                @include set-grid-prop(margin-left, 1, 0);
                padding-right: 10px;

                &:first-child {
                    margin-left: 0;
                }

                .title-menu {
                    @include apercu-pro-regular(25px, 65px);
                    color: $dark-blue;
                }

                ul {
                    margin-top: 20px;

                    li {
                        margin-top: 14px;

                        &:first-child {
                            margin-top: 0;
                        }

                        a {
                            display: block;
                            @include apercu-pro-regular(15px, 22px);
                            color: $dark-blue;
                            transition: color 0.6s $easeOutQuart;

                            html.desktop & {
                                &:hover {
                                    color: $brown;
                                }
                            }
                        }
                    }
                }
            }

            .column-3 {
                @include set-grid-prop(margin-left, 2, 1);
            }
        }

        .r-3 {
            padding: 50px 0;
            border-top: 1px solid rgba(0, 25, 49, 0.08);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            [class*='column'] {
                float: none;
            }

            .legend {
                @include apercu-pro-bold(11px, 17px, 0.4em);
                text-transform: uppercase;
            }

            a {
                @include apercu-pro-regular(16px, 28px);
                color: $dark-blue;
                transition: color 0.6s $easeOutQuart;

                html.desktop & {
                    &:hover {
                        color: $brown;
                    }
                }
            }

            .column-2 {
                @include set-grid-prop(margin-left, 1, 0);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .r-4 {
            padding: 50px 0;
            border-top: 1px solid rgba(0, 25, 49, 0.08);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    margin-left: 50px;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                a {
                    @include apercu-pro-regular(16px, 28px);
                    color: $dark-blue;
                    transition: color 0.6s $easeOutQuart;

                    html.desktop & {
                        &:hover {
                            color: $brown;
                        }
                    }
                }
            }

            .credits {
                margin-left: auto;
                @include apercu-pro-regular(16px, 16px);
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #footer {

        .section-links {
            padding-top: 60px;

            .r-1 {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 60px;

                .c-1 {

                    svg {
                        width: 168px;
                        margin: 0 auto;
                    }
                }

                .c-2 {
                    margin-top: 50px;

                    ul {
                        justify-content: center;
                    }
                }
            }

            .r-2 {
                display: none;
                margin-top: 60px;
                padding-bottom: 50px;

                .column-3,
                .column-2 {

                    ul {
                        display: none;
                    }
                }
            }

            .r-3 {
                display: none;
            }

            .r-4 {
                flex-direction: column;
                justify-content: center;
                text-align: center;

                .credits {
                    margin-left: auto;
                    margin-top: 40px;
                    width: 100%;
                    text-align: center;
                }

                ul {
                    flex-direction: column;

                    li {
                        margin-left: 0;
                        margin-top: 20px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}