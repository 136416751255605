.module-buttons {

    .column-8 {
        @include set-grid-prop(margin-left, 3, 3);
        display: flex;
        align-items: center;

        .button {
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .column-8 {
            flex-direction: column;
            align-items: flex-start;

            .button {
                margin-left: 0;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.module-title + .module-buttons,
.module-video + .module-buttons,
.module-text + .module-buttons {
    padding-top: 0;
    margin-top: 40px;
}