.module-img-text {

    .module-content {

        .r-1 {

            .column-11 {
                @include set-grid-prop(margin-left, 1, 1);
            }
        }

        .r-2 {
            margin-top: 100px;
        }

        .item {
            display: flex;
            align-items: stretch;
            background-color: $grey;

            [class*='column'] {
                float: none;
            }

            .illustration {
                position: relative;
                overflow: hidden;
                @include set-grid-prop(width, 5.5, 6);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &:after {
                    @include full-absolute;
                    content: "";
                    background: rgb(0,115,116);
                    background: linear-gradient(180deg, rgba(0,115,116,1) 0%, rgba(0,115,116,0) 100%);
                    z-index: 2;
                }
            }

            .content {
                @include set-grid-prop(margin-left, 2, 1);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 100px 0;

                .title {
                    margin-top: 40px;
                    @include apercu-pro-light(40px, 48px);
                    color: $dark-blue;
                }

                .description {
                    margin-top: 30px;

                    ul,
                    p {
                        margin-top: 26px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }

                    a {
                        color: $brown;
                        text-decoration: underline;
                        text-decoration-color: transparent;
                        transition: text-decoration-color .3s $easeOutQuart;
                        text-decoration-thickness: .04em!important;
                        text-underline-offset: .1em!important;

                        &:hover {
                            text-decoration-color: inherit;
                        }
                    }
                }

                .button {
                    margin-top: 30px;
                }
            }

            &.even {
                .content {
                    order: 1;
                    @include set-grid-prop(margin-left, 1, 1);
                }

                .illustration {
                    order: 2;
                    margin-left: auto;
                }
            }
        }
    }

    @media screen and (max-width: $tablet){
        .module-content {

            .item {
                .content {
                    @include set-grid-prop(margin-left, 1, 0.5);
                    @include set-grid-prop(width, 4, 5);
                }

                &.even {
                    .content {
                        @include set-grid-prop(margin-left, 0.5, 0.5);
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile){
        .module-content {

            .grid {
                width: 100%;
            }

            .r-1 {
                width: calc(100% - 60px);
                margin-left: auto;
                margin-right: auto;
            }

            .r-2 {
                margin-top: 30px;
            }

            .item {
                flex-direction: column;

                .illustration {
                    order: 1;
                    margin-left: 0;
                    margin-top: 0;
                }

                .content {
                    order: 2;
                    margin-left: 0;
                    margin-top: 0;
                    padding: 50px 30px;

                    .title {
                        font-size: 30px;
                        line-height: 40px;
                    }

                    .description {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}