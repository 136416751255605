.module-trombinoscope {

    .partial.title {
        margin-bottom: 40px;
    }

    .r-1 {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        [class*='column'] {
            float: none;
        }

        .column-5 {
            flex: 0 0 auto;
            @include set-grid-prop(margin-left, 1, 0);
            margin-top: 50px;
            display: flex;
            align-items: center;
            
            &.have-card {
                cursor: pointer;
            }

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }

            &:nth-child(2n + 1) {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .thumbnail {
                flex: 0 0 auto;
                width: 36%;
                height: auto;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .metas {
                padding-left: 6%;

                .metas__name {
                    @include austin-light(26px, 26px, 0.02em);
                    line-height: 1;
                    color: $dark-blue;
                }

                .metas__function {
                    margin-top: 8px;
                    @include apercu-pro-bold(16px, 20px, 0);
                    //text-transform: uppercase;
                    color: $dark-blue;
                }

                .metas__description {
                    margin-top: 30px;
                    @include apercu-pro-bold(14px, 20px);

                    p {
                        margin-top: 8px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .module-trombinoscope__card {
        @include autoAlpha(0);
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 25, 49, 0.2);
        z-index: 10000;
        display: flex;
        justify-content: flex-end;

        .close-outside {
            position: fixed;
            left: 0;
            height: 100vh;
            width: 26%;
        }

        .card__inner {
            position: relative;
            width: 74%;
            height: 100%;
            background-color: #a98c61;
            transform: translate(100%, 0);
        }

        .card__close {
            cursor: pointer;
            position: fixed;
            right: calc(4% + 30px);
            top: calc(4% + 30px);
            width: 48px;
            height: 48px;
            border: 2px solid $brown;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 4;

            svg {
                width: 16px;
                height: 16px;
                stroke: $brown;
                transition: transform 0.6s $easeInOutQuart;
            }

            html.desktop & {
                &:hover {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .card__wrapper {
            padding: 4%;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: flex-start;
        }

        .card__thumbnail {
            flex: 1 0 auto;
            position: relative;
            width: 28%;
            max-width: 322px;
        }

        .card__metas {
            background-color: white;
            height: 100%;
            //padding: 40px 10% 80px 10%;

            &:after {
                content: "";
                background: rgb(255,255,255);
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                position: absolute;
                height: 80px;
                width: 100%;
                right: 0;
                bottom: 0;
                z-index: 2;
                pointer-events: none;
            }

            .card__metas-inner {
                position: relative;
                overflow-y: auto;
                overflow-x: hidden;
                height: 100%;
                padding: 40px 10% 80px 10%;
            }

          /*  .card__metas-inner {
                width: calc(100% - 80px);
                margin: 10% auto 0 auto;
            }*/

            .name {
                @include austin-light(65px, 68px, 0.02em);
                color: $dark-blue;
            }

            .function {
                margin-top: 8px;
                @include apercu-pro-bold(16px, 20px, 0);
                //text-transform: uppercase;
                color: $dark-blue;
            }

            .description {
                margin-top: 30px;
                @include apercu-pro-bold(14px, 20px);

                p {
                    margin-top: 8px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .biography {
                margin-top: 40px;

                p {
                    @include apercu-pro-regular(16px, 28px);
                    margin-top: 14px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @media screen and(max-width: $mobile) {

        .card__inner {
            overflow-y: auto;

            .card__wrapper {
                height: auto;
            }
        }

        .r-1 {

            .column-5 {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 50px !important;

                &:first-child {
                    margin-top: 0 !important;
                }

                .metas {
                    margin-top: 25px;
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        }

        .module-trombinoscope__card {

            .card__close {
                top: 30px;
                right: 30px;
                border-color: $brown;

                svg {
                    stroke: $brown;
                }
            }

            .card__inner {
                width: 100%;
            }

            .card__wrapper {
                flex-direction: column;
                padding: 0;

                .card__thumbnail {
                    display: none;
                    width: 50%;
                }

                .card__metas {
                    padding-top: 90px;

                    .name {
                        font-size: 40px;
                        line-height: 1;
                    }

                    .function {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}