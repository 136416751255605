#header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: transform 0.6s $easeOutQuart;

    &.out {
        //transform: translate(0, -38px);

        .header__top {
            background-color: #072542;
            border-bottom-color: white;

            .header__top-left {
                opacity: 1 !important;
            }
        }

        .header__bottom {

            html.tablet &,
            html.desktop & {
                color: $dark-blue;
                height: 70px;
            }

            &:before {
                transform: scaleY(1);
                border-bottom: 1px solid #EBEDEF;
            }

            .header__bottom-logo {
                html.tablet &,
                html.desktop & {
                    svg {
                        fill: $dark-blue;
                        width: 110px;
                        height: 47px;
                        transition: all 0.6s $easeOutQuart;
                    }
                }
            }

            .header__bottom-menu {

                .main-menu {
                    li {
                        a {
                            &:after {
                                background-color: $brown;
                            }
                        }

                    }
                }

                .lang {
                    .ss-main .ss-single-selected {

                        .placeholder {
                            color: $dark-blue;
                        }
                    }

                    .ss-main .ss-single-selected .ss-arrow span.arrow-down {
                        border-color: $dark-blue;
                    }
                }

                .search-button {

                    svg {
                        fill: $dark-blue;
                    }
                }
            }
        }

        .search-form,
        .submenu {
            top: 108px;
        }
    }

    .header__top {
        height: 38px;
        //background-color: #072542;
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        transition: all 0.6s $easeOutQuart;

        body.home & {
            .header__top-left {
                opacity: 0;
            }
        }

        .grid {
            max-width: 100%;
            padding-left: 100px;
        }

        .grid,
        .row,
        .column-12 {
            height: 100%;
        }

        .column-12 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .header__top-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: opacity 0.6s $easeOutQuart;

            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }

            span {
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }

            .icon {
                svg {
                    width: 21px;
                    height: auto;
                }
            }

            .bourse,
            .anr {
                @include apercu-pro-bold(14px, 14px);
            }

            .anr {
                margin-left: 26px;
            }

            .bourse__date,
            .anr__date {
                @include apercu-pro-regular(14px, 14px);
            }
        }

        .header__top_right {
            margin-left: auto;
            height: 100%;

            ul {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                li {
                    height: 100%;

                    a {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        background-color: $brown;
                        padding: 0 26px;
                        color: white;
                        @include apercu-pro-bold(14px, 14px);
                        border-left: 1px solid rgba(255, 255, 255, 0.1);
                        transition: background-color 0.6s $easeOutQuart;

                        html.desktop & {
                            &:hover {
                                background-color: darken($brown, 5%);
                            }
                        }
                    }

                    &:first-child {
                        a {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    .header__bottom {
        position: relative;
        height: 110px;
        color: white;
        z-index: 2;

        &:before {
            content: "";
            background-color: white;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            transform: scaleY(0);
            transform-origin: 0 0;
            transition: transform 0.4s $easeInOutQuart;
        }

        /*body.home & {
            color: $dark-blue;

            &:before {
                transform: scaleY(1);
                border-bottom: 1px solid #EBEDEF;
            }
        }*/

        .grid,
        .row,
        .column-12 {
            height: 100%;
            z-index: 1;
        }

        .grid {
            max-width: 100%;
            padding: 0 100px;
        }

        .column-12 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .header__bottom-logo {

            a {
                display: block;
            }

            svg {
                width: 158px;
                height: auto;
                fill: white;

                body.home & {
                    //fill: $dark-blue;
                }
            }
        }

        .header__bottom-menu {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .main-menu {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    margin-left: 34px;

                    a {
                        position: relative;
                        @include apercu-pro-regular(16px, 16px);
                        padding-bottom: 8px;

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background-color: white;
                            height: 2px;
                            width: 42px;
                            transform-origin: 0 0;
                            transition: transform 0.6s $easeInOutQuart;
                            transform: scaleX(0);

                            body.home & {
                                background-color: $brown;
                            }
                        }

                        html.desktop & {
                            &:hover {
                                &:after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }

                    &.current-page-ancestor,
                    &.current-menu-item {
                        a {
                            &:after {
                                transform: scaleX(1);
                            }
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .lang {
                cursor: pointer;
                margin-left: 34px;
                height: 32px;
                position: relative;

                .ss-main .ss-single-selected {
                    background-color: transparent;
                    border: none;

                    .placeholder {
                        @include apercu-pro-regular(14px, 14px);
                        text-transform: uppercase;
                        color: white;
                    }
                }

                .ss-main .ss-single-selected .ss-arrow span.arrow-down {
                    margin-top: -5px;
                    border-color: white;
                }

                .ss-content .ss-list .ss-option:hover, .ss-content .ss-list .ss-option.ss-highlighted {
                    background: $dark-blue;
                }

                body.home & {
                    .ss-main .ss-single-selected {

                        .placeholder {
                            //color: $dark-blue;
                        }
                    }

                    .ss-main .ss-single-selected .ss-arrow span.arrow-down {
                        //border-color: $dark-blue;
                    }
                }

                .ss-content .ss-list .ss-option {
                    @include apercu-pro-regular(14px);
                    text-transform: uppercase;
                }

                /*select {
                    cursor: pointer;
                    @include apercu-pro-regular(14px, 14px);
                    height: 32px;
                    padding-right: 16px;
                    text-transform: uppercase;
                }

                svg {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    stroke: white;
                    width: 12px;
                    height: auto;

                    body.home & {
                        stroke: $dark-blue;
                    }
                }*/
            }

            .search-button {
                cursor: pointer;
                width: 16px;
                height: 16px;
                margin-left: 34px;

                svg {
                    width: 16px;
                    height: 16px;
                    fill: white;
                    transition: fill 0.6s $easeOutQuart;

                    body.home & {
                        //fill: $dark-blue;
                    }
                }

                /*html.desktop & {
                    &:hover {
                        svg {
                            fill: white;
                        }
                    }
                }*/
            }
        }
    }

    .header__mobile {
        display: none;
        margin-left: auto;

        a {
            width: 30px;
            height: 18px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;

            .line {
                width: 100%;
                height: 2px;
                margin-top: 4px;
                background-color: white;

                &.l-2 {
                    width: 20px;
                }

                &.l-3 {
                    width: 10px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .submenu {
        @include autoAlpha(0);
        position: absolute;
        background-color: white;
        top: 148px;
        left: 0;
        width: 100%;
        z-index: 1;
        box-shadow: 0 12px 24px rgba(0, 25, 49, 0.2);
        padding-left: 100px;

        &.visible {
            @include autoAlpha(1);
        }

        .submenu__inner {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
        }

        .submenu-left {
            padding: 70px 0;
            width: 62%;
            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            ul {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                justify-content: flex-start;
                width: 100%;

                li {
                    flex: 1 0 auto;
                    width: calc(50% - 30px);
                    margin-left: 30px;
                    //margin-top: 30px;
                    padding: 24px 0;
                    border-top: 1px solid #EBEDEF;

                    &:first-child,
                    &:nth-child(2) {
                        padding-top: 0;
                        border-top: none;
                    }

                    &.current-menu-item {
                         a {
                             span {
                                 color: $brown;
                             }
                         }
                    }

                    a {
                        display: block;

                        html.desktop & {
                            &:hover {
                                span {
                                    color: $brown;
                                }
                            }
                        }
                    }

                    span {
                        @include apercu-pro-regular(18px, 22px);
                        color: $dark-blue;
                        transition: color 0.6s $easeOutQuart;
                    }

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-top: 0;
                    }

                    &:nth-child(2n + 1) {
                        margin-left: 0;
                    }

                    &.society {

                        a {
                            display: flex;
                            align-items: center;

                            span {
                                display: block;
                            }

                            .logo {
                                flex: 0 0 auto;
                                width: 64px;
                                height: 64px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: $grey;
                                border-radius: 50%;
                                margin-right: 20px;

                                img {
                                    object-fit: contain;
                                    width: 70%;
                                    height: 60%;
                                }
                            }

                            .metas {
                                flex: 1 0 auto;

                                .sector {
                                    @include apercu-pro-regular(16px, 24px);
                                    max-width: 70%;
                                    color: $text-color;
                                }

                                .percent {
                                    @include apercu-pro-light(24px, 24px);
                                    margin-top: 6px;
                                    color: $brown;
                                }
                            }
                        }

                        &.wendel {
                            .logo {
                                background-color: $brown;
                            }
                        }

                        &.only-logo {

                            a {

                                .logo {
                                    flex: 0 0 auto;
                                    width: auto;
                                    height: auto;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: transparent;
                                    border-radius: 0;
                                    margin-right: 0;

                                    img {
                                        object-fit: none;
                                        width: auto;
                                        height: auto;
                                        max-width: 100%;
                                    }
                                }

                                .metas {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .submenu-right {
            margin-left: auto;
            width: 38%;
            background-color: #F7F8F8;
            padding: 70px 60px;
            max-width: 580px;

            .title {
                @include apercu-pro-bold(11px, 11px, 0.4em);
                text-transform: uppercase;
                color: $brown;
            }

            ul {
                margin-top: 30px;
                width: 100%;

                li {

                    &:first-child {
                        border-top: none;

                        a {
                            border-top: none;
                        }
                    }

                    a {
                        position: relative;
                        border-top: 1px solid #DDE0E5;
                        padding: 20px 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        html.desktop & {

                            &:hover {

                                svg {
                                    transform: translate(4px, 0);
                                    fill: $brown;
                                }

                                span {
                                    color: $brown;
                                }
                            }
                        }
                    }

                    svg {
                        width: 16px;
                        height: 17px;
                        fill: $dark-blue;
                        transition: transform 0.6s $easeOutQuart, fill 0.6s $easeOutQuart;
                    }

                    span {
                        width: calc(100% - 100px);
                        padding-left: 10px;
                        @include apercu-pro-regular(16px, 24px);
                        color: $dark-blue;
                        transition: color 0.6s $easeOutQuart;
                    }

                    img {
                        position: absolute;
                        right: 14px;
                        bottom: 0;
                        box-shadow: 3px 2px 11px rgba(0, 0, 0, 0.2);
                    }

                    &.no-text {
                        svg,
                        span {
                            display: none;
                        }

                        img {
                            left: 0;
                            right: auto;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    .search-form {
        position: absolute;
        background-color: white;
        top: 148px;
        left: 0;
        width: 100%;
        z-index: 1;
        box-shadow: 0 12px 24px rgba(0, 25, 49, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 100px;
        height: 152px;
        @include autoAlpha(0);

        &.visible {
            @include autoAlpha(1);
        }

        .search-icon {
            flex: 0 0 auto;
            width: 16px;
            height: 16px;
        }

        form {
            height: 68px;
            border-left: 1px solid $dark-blue;
            width: calc(100% - 140px);
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            input[type='text'] {
                @include apercu-pro-light(40px, 68px);
                height: 100%;
                width: 100%;
                color: $dark-blue;
                padding-left: 10px;

                &::placeholder {
                    color: #AFB7BE;
                }
            }

            input[type='submit'] {
                position: absolute;
                right: 0;
                pointer-events: none;
                opacity: 0;
            }
        }

        .button-close {
            cursor: pointer;
            flex: 0 0 auto;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            border: 2px solid $brown;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            svg {
                width: 16px;
                height: 16px;
                stroke: $brown;
                transition: transform 0.6s $easeInOutQuart;
            }

            html.desktop & {
                &:hover {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $small){
    #header {

        .header__top {

            .grid {
                padding-left: 60px;
                width: 100%;
            }
        }

        .header__bottom {

            .grid {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #header {

        &.out {
            transform: translate(0, 0);

            .header__bottom {
                body.home & {
                    &:before {
                        transform: scaleY(1);
                    }
                }
            }
        }

        .header__top {
            //display: none;

            .grid {
                padding: 0 20px;
            }

            .header__top-left {

                .bourse,
                .bourse__date {
                    font-size: 12px;
                    line-height: 1;
                }

                .anr,
                .anr__date {
                    display: none;
                }
            }

            .header__top_right {
                display: none;
            }
        }

        .header__bottom {
            height: 80px;

            &:before {
                background-color: $dark-blue;
            }

            body.home & {
                &:before {
                    transform: scaleY(0);
                }
            }

            .grid {
                max-width: 500px;
            }

            .header__bottom-logo {

                svg {
                    width: 114px;
                    fill: white !important;
                }
            }

            .header__bottom-menu {
                display: none;
            }
        }

        .header__mobile {
            display: block;
        }
    }
}