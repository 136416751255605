.module-video {
    //padding-top: 50px;

    .column-8 {
        @include set-grid-prop(margin-left, 3, 3);
    }

    &.is-center-1 {
        .column-8 {
            float: none;
            position: relative;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: $mobile) {
        .video-thumbnail {
            height: 300px;
        }
    }
}

.module-title + .module-video,
.module-buttons + .module-video,
.module-text + .module-video {
    padding-top: 0;
    margin-top: 40px;
}