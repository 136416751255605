.module-title {

    .title {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .subtitle {
        @include set-grid-prop(margin-left, 2, 1);
    }

    + .module-text {
        padding-top: 0;
        margin-top: 60px;
    }

    @media screen and (max-width: $mobile) {
        .subtitle {
            margin-top: 40px;
        }
    }
}