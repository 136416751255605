#menu-mobile {
    @include autoAlpha(0);
    display: none;
    position: fixed;
    background-color: white;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding-bottom: 60px;

    .menu-mobile__top {
        height: 80px;
        border-bottom: 1px solid #EBEDEF;

        .grid,
        .row,
        .column-12 {
            height: 100%;
        }

        .column-12 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .menu-mobile__top-logo {
            svg {
                width: 114px;
                height: auto;
            }
        }

        .menu-mobile__top-close {
            margin-left: auto;

            svg {
                width: 16px;
                height: 16px;
                stroke: $brown;
            }
        }
    }

    .menu-mobile__search {
        height: 46px;
        background-color: $grey;

        .grid,
        .row,
        .column-12 {
            height: 100%;
        }

        .column-12 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .lang-container {
            flex: 0 0 auto;

            ul {
                display: flex;
                align-items: center;

                li {
                    border-left: 1px solid $dark-blue;

                    &:first-child {
                        border-left: none;

                        a {
                            padding-left: 0;
                        }
                    }

                    a {
                        padding: 2px 10px 0 10px;
                        display: block;
                        @include apercu-pro-light(14px, 14px);
                        text-transform: uppercase;
                        color: $dark-blue;

                        &.active {
                            @include apercu-pro-bold();
                        }
                    }
                }
            }
        }

        .search-container {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: calc(100% - 80px);
            flex: 1 0 auto;

            form {
                position: relative;
                width: 80%;
            }

            input[type="text"] {
                width: 100%;
                font-size: 14px;
                line-height: 1;
                color: $dark-blue;
            }

            input[type="submit"] {
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                opacity: 0;
            }

            svg {
                margin-left: 10px;
                width: 16px;
                height: 16px;
            }
        }
    }

    .menu-mobile__main-links {
        padding-top: 40px;

        .main-list {

            .main-list__li {
                margin-top: 26px;

                &:first-child {
                    margin-top: 0;
                }

                .main-list__a {
                    display: block;
                    position: relative;

                    span {
                        @include apercu-pro-regular(22px, 28px);
                        color: $dark-blue;
                    }

                    svg {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        width: 12px;
                        height: auto;
                        stroke: $dark-blue;
                    }
                }

                .main-list__slave {
                    position: relative;
                    overflow: hidden;
                    height: 0;

                    ul {
                        padding-top: 30px;

                        li {
                            margin-top: 8px;

                            &:first-child {
                                margin-top: 0;
                            }

                            a {
                                @include apercu-pro-regular(14px, 24px);
                            }
                        }
                    }
                }
            }
        }
    }

    .menu-mobile__side_links {
        margin-top: 30px;

        ul {

            li {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }

                a {
                    @include apercu-pro-regular(16px, 16px);
                    color: $brown;
                }
            }
        }
    }

    .menu-mobile__socials {
        margin-top: 50px;

        ul {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            li {
                margin-left: 50px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    display: block;

                    svg {
                        width: 20px;
                        height: auto;
                        fill: $brown;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #menu-mobile {
        display: block;
    }
}