/*********************
BASE
*********************/
@import "base/normalize";
@import "base/reset";
@import "base/ress";

/*********************
HELPERS
*********************/
@import "helpers/functions";
@import "helpers/easing";
@import "helpers/helpers";
@import "helpers/basicLightbox.min";
@import "helpers/_slimselect.css";

/*********************
COMMON
*********************/
@import "common/grid";
@import "common/fonts";
@import "common/colors";
@import "common/buttons";
@import "common/animations";
@import "common/form";
@import "common/content";

/*********************
HEADER
*********************/
@import "components/header";

/*********************
FOOTER
*********************/
@import "components/footer";

/*********************
PARTIALS
*********************/
@import "components/notations";
@import "components/menu-mobile";
@import "components/partials";

/*********************
MODULES
*********************/
@import "modules/modules";
@import "modules/module-key-numbers";
@import "modules/module-companies";
@import "modules/module-big-boxes";
@import "modules/module-small-boxes";
@import "modules/module-timeline";
@import "modules/module-img-text";
@import "modules/module-video-text";
@import "modules/module-slider";
@import "modules/module-news";
@import "modules/module-new-and-cp";
@import "modules/module-accompaniment";
@import "modules/module-title";
@import "modules/module-text";
@import "modules/module-introduction";
@import "modules/module-buttons";
@import "modules/module-video";
@import "modules/module-title-text";
@import "modules/module-trombinoscope";
@import "modules/module-verbatim";
@import "modules/module-leaders";
@import "modules/module-cover";
@import "modules/module-accordion";

/*********************
PAGE
*********************/
@import "layouts/page";

/*********************
PAGES
*********************/
@import "pages/home";
@import "pages/news";
@import "pages/single";
@import "pages/search";
@import "pages/common";