.module-accompaniment {
    background-color: $grey;

    .g-1 {
        padding-bottom: 70px;

        .r-1 {
            margin-top: 40px;
            padding-bottom: 60px;

            .column-5 {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .column-4 {
                @include set-grid-prop(margin-left, 2, 1);
                margin-top: 40px;

                .quote,
                .text-content {
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .g-2 {
        background-color: white;

        &:before {
            content: "";
            width: 500%;
            height: 100%;
            @include center-x;
            background-color: white;
        }

        .column-4 {
            position: absolute;
            background-color: $brown;
            @include set-grid-prop(left, 1, 1);
            z-index: 2;
            top: -86px;

            ul {
                padding: 0 11%;

                li {
                    padding: 30px 0;
                    border-top: 1px solid rgba(255, 255, 255, 0.3);

                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }

        .column-8 {
            @include set-grid-prop(margin-left, 4, 4);

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .g-1 {
            .r-1 {
                .column-4 {
                    margin-top: 80px;
                }
            }
        }

        .g-2 {
            background-color: white;
            width: 100%;

            &:before {
                display: none;
            }

            .column-4 {
                position: relative;
                top: auto;
                left: auto;
            }

            .column-8 {
                display: none;
            }
        }
    }
}