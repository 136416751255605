#news {

    .news-cover {
        position: relative;
        min-height: 580px;

        .background {
            @include full-absolute;
            height: 580px;
            z-index: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid {
            z-index: 2;
            padding-top: 400px;

            .column-6 {
                position: relative;
                @include set-grid-prop(margin-left, 6, 6);
                background: $grey;

                a {
                    display: block;
                    padding: 50px;

                    html.desktop & {
                        &:hover {
                            .title {
                                text-decoration-color: $dark-blue;
                            }
                        }
                    }
                }

                &:after {
                    pointer-events: none;
                    content: "";
                    background: $grey;
                    height: calc(100% + 340px);
                    width: 100vw;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }

                .date {
                    position: relative;
                    z-index: 2;
                    @include apercu-pro-bold(12px, 12px, 0.3em);
                    color: rgba(0, 25, 49, 0.4);
                }

                .title {
                    position: relative;
                    z-index: 2;
                    margin-top: 20px;
                    @include apercu-pro-light(40px, 48px);
                    color: $dark-blue;
                    padding-right: 10%;
                    text-decoration: underline;
                    text-decoration-color: transparent;
                    transition: text-decoration-color .3s $easeOutQuart;
                    text-decoration-thickness: .04em!important;
                    text-underline-offset: .1em!important;
                }

                .tags {
                    position: relative;
                    z-index: 2;
                    margin-top: 40px;
                    @include apercu-pro-bold(12px, 12px, 0.4em);
                    color: $brown;
                    text-transform: uppercase;
                }
            }
        }
    }


    .news-metas {
        margin-top: 40px;
        position: relative;
        z-index: 2;

        .row {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            [class*='column'] {
                float: none;
            }
        }

        .column-5 {

            .list-filters {
                display: flex;
                align-items: flex-start;

                .list-filters__legend {
                    flex: 0 0 auto;
                    @include apercu-pro-bold(12px, 12px, 0.4em);
                    text-transform: uppercase;
                    margin-top: 6px;
                }

                .list-filters__content {
                    margin-left: 8px;

                    ul {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        li {
                            margin-left: 12px;
                            margin-bottom: 14px;
                            @include apercu-pro-bold(12px, 12px, 0.4em);
                            color: white;
                            background: $text-color;
                            border-radius: 2px;
                            padding: 6px 12px 4px 12px;
                            text-transform: uppercase;
                            transition: background-color 0.6s $easeOutQuart;

                            html.desktop & {
                                &:hover {
                                    background-color: $brown;
                                }
                            }

                            &.selected {
                                background-color: $brown;
                            }
                        }
                    }
                }
            }
        }

        .column-2 {
            margin-left: auto;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                li {
                    margin-left: 25px;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        display: block;
                    }

                    svg {
                        width: 26px;
                        height: 26px;
                        fill: $brown;
                        transition: fill 0.6s $easeOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            svg {
                                fill: darken($brown, 5%);
                            }
                        }
                    }

                    &.twitter {
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    .news-content {
        position: relative;
        z-index: 2;
        margin-top: 50px;

        .row {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;

            [class*='column'] {
                float: none;
                flex: 0 0 auto;
            }
        }

        .new-item {
            position: relative;
            @include set-grid-prop(margin-left, 1, 0);
            margin-bottom: 60px;

            &:nth-child(3n + 1) {
                margin-left: 0;
            }

            a {
                display: block;

                html.desktop & {
                    &:hover {

                        .new-item__thumbnail {
                            &:after {
                                opacity: 0;
                            }
                        }

                        .title {
                            text-decoration-color: $dark-blue;
                        }
                    }
                }
            }

            .new-item__thumbnail {
                position: relative;
                height: 258px;
                overflow: hidden;
                width: 100%;

                &:after {
                    content: "";
                    background-color: $dark-blue;
                    opacity: 0.2;
                    @include full-absolute;
                    z-index: 2;
                    transition: opacity 0.6s $easeOutQuart;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .new-item__metas {
                margin-top: 30px;

                .date {
                    @include apercu-pro-bold(12px, 12px, 0.4em);
                }

                .title {
                    margin-top: 20px;
                    @include apercu-pro-light(26px, 32px);
                    color: $dark-blue;
                    text-decoration: underline;
                    text-decoration-color: transparent;
                    transition: text-decoration-color .3s $easeOutQuart;
                    text-decoration-thickness: .04em!important;
                    text-underline-offset: .1em!important;
                }

                .tags {
                    margin-top: 20px;
                    @include apercu-pro-bold(12px, 12px, 0.4em);
                    color: $brown;
                    text-transform: uppercase;
                }
            }
        }
    }

    .newsletter {
        margin-top: 120px;
        padding: 100px 0;
        background-color: $brown;

        .text-content {
            p {
                color: #E3D2B9;

                a {
                    color: white;
                    @include apercu-pro-bold();
                }

                strong {
                    color: white;
                }
            }
        }

        .column-5 {
            position: relative;
            @include set-grid-prop(margin-left, 1, 1);

            .icon {
                position: absolute;
                left: -30px;
                top: 8px;
                transform: translate(-100%, 0);

                svg {
                    width: 50px;
                    height: 50px;
                }
            }

            .title {
                @extend h2;
                color: white;
            }

            .description {
                margin-top: 20px;
            }

            .form-container {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .button {
                    margin-left: 20px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .column-4 {
            margin-top: 60px;
            @include set-grid-prop(margin-left, 2, 1);
        }
    }

    @media screen and (max-width: $mobile) {
        .news-cover {

            .background {
                height: 280px;
            }

            .grid {
                padding-top: 280px;
                width: 100%;

                .column-6 {
                    background: $grey;

                    a {
                        padding: 40px 30px;
                    }

                    &:after {
                        display: none;
                    }

                    .title {
                        font-size: 36px;
                        line-height: 44px;
                    }
                }
            }
        }

        .news-metas {

            .column-5 {

                .list-filters {
                    flex-direction: column;

                    .list-filters__content {
                        margin-top: 20px;
                        margin-left: -12px;
                    }
                }
            }

            .column-2 {
                display: none;
            }
        }

        .news-content {
            margin-top: 30px;
        }

        .newsletter {
            margin-top: 0;
            padding-bottom: 70px;

            .column-5 {

                .icon {
                    left: 0;
                    top: -20px;
                    transform: translate(0, -100%);

                    svg {
                        width: 40px;
                        height: 40px;
                    }
                }


            }
        }
    }

}