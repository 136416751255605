/**
ARROW BUTTON
 */
.button.arrow-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        html.desktop & {
            &:hover {
                svg {
                    transform: translate(4px, 0);
                }
            }
        }
    }

    svg {
        width: 16px;
        height: 18px;
        transition: transform 0.6s $easeOutQuart;
        margin-top: 4px;
    }

    .text {
        @include apercu-pro-regular(16px, 24px);
        margin-left: 12px;
    }

    .module.blue &,
    .module.green & {
        svg {
            fill: white;
        }

        .text {
            color: white;
        }
    }

    &.white {
        svg {
            fill: white;
        }

        .text {
            color: white;
        }
    }

    &.brown {
        svg {
            fill: $brown;
        }

        .text {
            color: $brown;
        }
    }
}

/**
VIDEO BUTTON
 */
.button-video {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
        width: 40px;
        height: 40px;
        border: 2px solid white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 4px;

        svg {
            width: 20px;
            height: auto;
            fill: white;
        }
    }

    .text {
        @include apercu-pro-regular(16px, 16px);
        color: white;
        margin-left: 12px;
    }
}

/**
DOWNLOAD BUTTON
 */
.download-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.img {
        width: 100%;

        a {
            border-bottom: 1px solid #DDE0E5;
            padding: 20px 56px 10px 0;
            width: 100%;

            img {
                position: absolute;
                right: 14px;
                bottom: 0;
                box-shadow: 3px 2px 11px rgba(0, 0, 0, 0.2);
                width: 38px;
                height: auto;
            }
        }
    }

    a {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        html.desktop & {
            &:hover {
                svg {
                    transform: translate(0, 2px);
                }
            }
        }
    }

    svg {
        flex: 0 0 auto;
        width: 16px;
        height: 18px;
        transition: transform 0.3s $easeOutQuart;
        margin-top: 2px;
    }

    .text {
        @include apercu-pro-regular(16px, 24px);
        margin-left: 12px;
        color: $dark-blue;
    }

    .module.blue &,
    .module.green & {
        svg {
            fill: white;
        }

        .text {
            color: white;
        }
    }

    &.white {
        svg {
            fill: white;
        }

        .text {
            color: white;
        }
    }

    &.brown {
        svg {
            fill: $brown;
        }

        .text {
            color: $brown;
        }
    }
}

/**
BUTTON LINE
 */
.button-line {
    margin-left: 20px;

    &:first-child {
        margin-left: 0;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $brown;
        border-radius: 20px;
        height: 40px;
        padding: 0 20px;
        font-size: 16px;
        color: $brown;
        line-height: 1;
        transition: all 0.6s $easeOutQuart;

        html.desktop & {
            &:hover {
                background-color: $brown;
                color: white;
            }
        }
    }
}