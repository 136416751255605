#home {

    .item-news {
        max-width: 570px;
        width: 38vw;
        background-color: white;
        height: 110px;
        display: flex;
        align-items: flex-end;

        .cross {
            position: absolute;
            right: 20px;
            top: 50%;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            border: 1px solid rgba(158, 131, 90, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate(0, -50%);
            transition: border-color 0.6s $easeOutQuart;

            svg {
                width: 12px;
                height: 12px;
                stroke: $brown;
                transform: rotate(-45deg);
            }

            html.desktop & {
                &:hover {
                    border-color: $brown;
                }
            }
        }

        .thumbnails {
            height: 135px;
            position: relative;
            overflow: hidden;
            width: 33%;

            .thumb-item {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                @include autoAlpha(0);

                &:first-child {
                    @include autoAlpha(1);
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .links {
            position: relative;
            width: calc(100% - 33%);
            height: 100%;
            padding: 20px 14% 20px 20px;

            .new-item {
                position: absolute;
                width: calc(100% - 90px);
                height: 100%;
                z-index: 1;
                @include autoAlpha(0);

                &:first-child {
                    @include autoAlpha(1);
                }

                a {
                    display: block;

                    svg {
                        display: inline;
                        width: 16px;
                        height: auto;
                        vertical-align: middle;
                        margin-left: 4px;
                    }

                    .title {
                        display: inherit;
                        @include apercu-pro-regular(16px, 22px);
                        color: $dark-blue;
                    }
                }
            }
        }

        .bullets {
            position: absolute;
            z-index: 10;
            left: 20px;
            bottom: 14px;
            display: flex;
            align-items: center;

            .bullet-item {
                cursor: pointer;
                margin-left: 10px;
                width: 6px;
                height: 6px;
                background-color: $brown;
                border-radius: 50%;
                opacity: 0.4;
                transition: opacity 0.6s $easeOutQuart;

                &:first-child {
                    margin-left: 0;
                }

                &.current {
                    opacity: 1;
                }

                html.desktop & {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .home-head {
        //margin-top: 112px;
        background-color: $grey;

        .home-head__background {
            @include full-absolute;
            height: 640px;
            overflow: hidden;
            z-index: 1;
            background-color: $dark-blue;

            .grid,
            .row {
                height: 100%;
            }

            &:after {
                content: "";
                @include full-absolute;
                background: rgb(0,25,49);
                background: linear-gradient(180deg, rgba(0,25,49,1) 0%, rgba(0,25,49,0) 100%);
                z-index: 200;
                opacity: 0.8;
            }

            img {
                @include full-absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                opacity: 0;

                &:first-child {
                    opacity: 1;
                }
            }
        }

        .home-head__grid {
            height: 640px;
            z-index: 2;

            .r-1 {
                position: relative;
                height: 100%;
                z-index: 2;
            }

            .c-2 {
                padding-top: 250px;
                height: 100%;

                .title-left {
                    @include apercu-pro-light(68px, 68px);
                    color: white;
                    transform: translate(0, 20px);
                    opacity: 0;
                }

                .slider-item {
                    position: absolute;
                    left: 0;
                    top: 0;
                    padding-top: 320px;
                    display: none;
                    height: 100%;

                    .title {
                        @include apercu-pro-light(68px, 68px);
                        color: white;

                        html.is-safari &,
                        html.mobile & {
                            @include austin-light();
                            font-style: italic;
                        }

                        .word {
                            transform: translate(0, 20px);
                            opacity: 0;
                        }
                    }
                }
            }

            .button {
                margin-top: 100px;
                transform: translate(0, 20px);
                opacity: 0;
                /*position: absolute;
                left: 0;
                bottom: 190px;
                z-index: 2;*/

                svg {
                    margin-top: 2px;
                }

                .text {
                    font-size: 18px;
                }
            }

            .bullets-container {
                position: absolute;
                right: 0;
                bottom: 200px;
                width: 290px;
                height: 2px;
                background-color: rgba(229, 229, 229, 0.5);
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .bullet {
                    position: relative;
                    flex: 1 0 auto;
                    height: 100%;
                    background-color: white;
                    transform-origin: 0 0;
                    transform: scaleX(0);
                }
            }
        }

        .toolbars {
            position: absolute;
            z-index: 10;
            bottom: 0;
            right: 0;
            width: auto;
            display: flex;
            align-items: flex-end;

            &:hover {
                z-index: 10000;
            }

            .item-bourse {
                width: 220px;
                height: 110px;
                background-color: $brown;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;

                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .item-bourse__left {

                    svg {
                        width: 21px;
                        height: auto;
                    }
                }

                .item-bourse__right {
                    margin-left: 10px;

                    .bourse {
                        @include apercu-pro-bold(16px, 19px);
                    }

                    .bourse__date {
                        margin-top: 4px;
                        @include apercu-pro-regular(13px, 16px);
                        color: #E3D2B9;
                    }
                }
            }

            .item-socials {
                background-color: $dark-blue;
                height: 110px;
                //width: 22vw;
                max-width: 330px;
                width: 330px;

                ul {
                    display: flex;
                    align-items: stretch;
                    height: 100%;

                    li {
                        //width: calc(100% / 3);
                        max-width: 110px;
                        width: 110px;
                        height: 100%;
                        background-color: transparent;
                        transition: background-color 0.6s $easeOutQuart;
                        border-left: 1px solid #37536D;

                        &:first-child {
                            border-left: none;
                        }

                        html.desktop & {
                            &:hover {
                                background-color: darken($dark-blue, 5%);
                            }
                        }

                        > a {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        svg {
                            fill: white;
                            width: 20px;
                        }
                    }
                }

                .twitter {
                    position: relative;

                    svg.arrow {
                        width: 12px;
                        margin-left: 10px;
                        transform: rotate(-180deg);
                    }

                    html.desktop & {
                        &:hover {

                            svg.arrow {
                                transform: rotate(0);
                            }

                            .tweets {
                                @include autoAlpha(1)
                            }
                        }
                    }

                    .tweets {
                        @include autoAlpha(0);
                        transition: opacity 0.3s $easeOutQuart;
                        position: absolute;
                        top: 0;
                        right: -110px;
                        width: 436px;
                        background-color: rgba(7, 37, 66, 0.9);
                        height: 114px;
                        transform: translate(0, -100%);
                        overflow: hidden;
                        padding: 40px 40px 0 40px;

                        &:after {
                            pointer-events: none;
                            content: "";
                            position: absolute;
                            z-index: 2;
                            bottom: 0;
                            left: 0;
                            background: rgb(10,12,27);
                            background: linear-gradient(0deg, rgba(7,37,66,1) 0%, rgba(7,37,66,0) 100%);
                            height: 50px;
                            width: 100%;
                        }

                        .tweets__head {
                            display: flex;
                            align-items: center;

                            .title {
                                @include apercu-pro-light(24px, 24px);
                                color: white;
                            }

                            .button {
                                margin-left: auto;
                                border: 2px solid rgba(255, 255, 255, 0.4);
                                border-radius: 20px;
                                transition: border-color 0.6s $easeOutQuart;

                                html.desktop & {
                                    &:hover {
                                        border-color: white;
                                    }
                                }

                                a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 36px;
                                    padding: 0 14px;
                                    color: white;
                                    @include apercu-pro-light(16px, 16px);

                                    svg {
                                        width: 14px;
                                        height: auto;
                                        margin-right: 6px;
                                    }
                                }
                            }

                        }

                        .scroll-content {
                            padding-right: 20px;
                        }

                        .scrollbar-track {
                            z-index: 4;
                            background-color: rgba(255, 255, 255, 0.4);
                            width: 2px;

                            .scrollbar-thumb {
                                background-color: white;
                            }

                        }

                        .tweets__wrapper {
                            margin-top: 40px;
                            width: 100%;
                            height: 350px;
                            padding-bottom: 20px;

                            .tweet-item {
                                margin-top: 20px;
                                padding-top: 20px;
                                border-top: 1px solid rgba(255, 255, 255, 0.1);
                                @include apercu-pro-regular(14px, 22px);
                                color: white;

                                &:first-child {
                                    border-top: none;
                                }

                                &:first-child {
                                    margin-top: 0;
                                }

                                a {
                                    @include apercu-pro-bold();

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-mobile-news {
        display: none;
    }

    .home-communique {
        padding: 80px 0;
        background-color: white;

        .c-1 {
            @include set-grid-prop(margin-left, 1, 1);
            @include apercu-pro-light(30px, 42px);
            @include set-grid-prop(width, 3, 3);
            color: $dark-blue;
            opacity: 0.4;
        }

        .c-2,
        .c-3 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .title {
            @include apercu-pro-bold(18px, 18px);
            color: $dark-blue;
        }

        .cp-item {
            padding: 30px 0;
            border-top: 1Px solid #EBEDEF;

            .cp-item__date {
                @include apercu-pro-bold(12px, 12px, 0.3em);
                color: $dark-blue;
                opacity: 0.4;
            }

            .cp-item__title {
                @include apercu-pro-light(22px, 28px);
                color: $dark-blue;
                margin-top: 26px;
                transition: opacity 0.6s $easeOutQuart;
            }

            html.desktop & {
                &:hover {
                    .cp-item__title {
                        opacity: 0.6;
                    }
                }
            }

            &:first-child {
                border-top: none;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
     #home {

         .home-head {

             .home-head__grid {

                 .button {
                     //bottom: 150px;
                 }

                 .bullets-container {
                     width: 150px;
                     bottom: 164px;
                 }
             }
         }
     }
}

@media screen and (max-width: $mobile) {
    #home {

        .item-news {
            width: 100%;
            border-bottom: 1px solid #EBEDEF;

            .thumbnails {
                display: none;
            }

            .links {
                width: 100%;

                .new-item {
                    a {
                        .title {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .home-mobile-news {
            display: block;
        }

        .home-head {
            margin-top: 0;

            .toolbars {
                width: 100%;

                .item-bourse {
                    width: calc((100% / 5) * 2);
                    height: 66px;
                    padding: 0 10px;

                    .item-bourse__left {
                        display: none;
                    }

                    .item-bourse__right {
                        margin-left: 0;

                        .bourse {
                            font-size: 11px;
                        }
                        .bourse__date {
                            font-size: 10px;
                        }
                    }
                }

                .item-news {
                    display: none;
                }

                .item-socials {
                    width: calc((100% / 5) * 3);
                    height: 66px;

                    ul {

                        li {
                            height: 66px;

                            svg {
                                width: 15px;
                            }
                        }
                    }
                }
            }

            .home-head__background {
                height: 544px;
            }

            .home-head__grid {
                height: 544px;

                .row {
                    height: 100%;
                }

                .r-1 {
                    padding-bottom: 66px;
                }

                .c-2 {
                    height: 100%;
                    padding-top: 158px;

                    .title-left {
                        text-align: left;
                        font-size: 52px;
                        line-height: 1;
                    }

                    .slider-item {
                        padding-top: 212px;
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);

                        .title {
                            font-size: 46px;
                            line-height: 1;
                        }

                        .subtitle {
                            display: none;
                        }
                    }
                }

                .bullets-container {
                    display: none;
                }

                .button {
                    margin-top: 120px;
                    bottom: auto;

                    svg {
                        margin-top: 0;
                    }

                    .text {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }

            .module-new-and-cp {

                .column-8 {
                    margin-top: 0;

                    &:after {
                        display: none;
                    }

                }
            }
        }

        .home-communique {
            padding: 60px 0;

            .c-1 {
                display: none;
            }

            .c-3 {
                margin-top: 30px;
                padding-top: 30px;
                border-top: 1px solid rgba(0, 25, 49, 0.08);
            }

            .title {
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 30px;
            }

            .cp-item {
                padding-top: 0;
                border-top: none;

                .cp-item__title {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}
