#single {

    .single-cover {

        .background {
            @include full-absolute;
            height: 620px;
            z-index: 1;

            &:after {
                content: "";
                @include full-absolute;
                background: rgb(0,25,49);
                background: linear-gradient(180deg, rgba(0,25,49,1) 0%, rgba(0,25,49,0) 100%);
                z-index: 200;
                opacity: 0.8;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid {
            z-index: 2;
            padding-top: 530px;

            /*&:before {
                content: "";
                position: absolute;
                top: 430px;
                @include set-grid-prop(left, 1, 1);
                background-color: white;
                height: 100%;
                width: 100vw;
            }*/

            .column-8 {
                @include set-grid-prop(margin-left, 2, 2);

                &:before {
                    content: "";
                    position: absolute;
                    top: -100px;
                    left: -100px;
                    background-color: white;
                    height: calc(100% + 100px);
                    width: 100vw;
                }

                h1 {
                    color: $dark-blue;
                    @include austin-light(72px, 78px);
                }

                .metas {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .date {
                        @include apercu-pro-bold(12px, 12px, 0.4em);
                        text-transform: uppercase;
                    }

                    .category {
                        margin-left: 20px;
                        @include apercu-pro-bold(12px, 12px, 0.4em);
                        text-transform: uppercase;
                        color: $brown;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .single-content {
        position: relative;
        z-index: 2;
    }

    @media screen and (max-width: $mobile) {
        .single-cover {

            .background {
                height: 280px;
            }

            .grid {
                width: 100%;
                padding-top: 280px;

                .column-8 {
                    padding: 40px 30px 0 30px;

                    &:before {
                        display: none;
                    }

                    h1 {
                        font-size: 40px;
                        line-height: 46px;
                    }

                    .metas {
                        flex-direction: column;
                        align-items: flex-start;

                        .category {
                            margin-left: 0;
                            margin-top: 10px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

}