@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

/* Webfont: ApercuPro-Bold */@font-face {
    font-family: 'ApercuProBold';
    src: url('../fonts/ApercuPro-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ApercuPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ApercuPro-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ApercuPro-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ApercuPro-Bold.svg#ApercuPro-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: ApercuPro-Italic */@font-face {
    font-family: 'ApercuProItalic';
    src: url('../fonts/ApercuPro-Italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ApercuPro-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ApercuPro-Italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ApercuPro-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ApercuPro-Italic.svg#ApercuPro-Italic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ApercuPro-Light */@font-face {
    font-family: 'ApercuProLight';
    src: url('../fonts/ApercuPro-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ApercuPro-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ApercuPro-Light.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ApercuPro-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ApercuPro-Light.svg#ApercuPro-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ApercuPro-Regular */@font-face {
    font-family: 'ApercuProRegular';
    src: url('../fonts/ApercuPro-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ApercuPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ApercuPro-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ApercuPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ApercuPro-Regular.svg#ApercuPro-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Austin-Light */@font-face {
    font-family: 'AustinLight';
    src: url('../fonts/Austin-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Austin-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Austin-Light.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Austin-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Austin-Light.svg#Austin-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Austin-LightItalic */@font-face {
    font-family: 'AustinLightItalic';
    src: url('../fonts/Austin-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Austin-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Austin-LightItalic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Austin-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Austin-LightItalic.svg#Austin-LightItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Publico-Light */@font-face {
    font-family: 'PublicoLight';
    src: url('../fonts/Publico-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Publico-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Publico-Light.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Publico-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Publico-Light.svg#Publico-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Publico-LightItalic */@font-face {
    font-family: 'PublicoLightItalic';
    src: url('../fonts/Publico-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Publico-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Publico-LightItalic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Publico-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Publico-LightItalic.svg#Publico-LightItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


@mixin apercu-pro-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ApercuProBold', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin apercu-pro-italic($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ApercuProItalic', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin apercu-pro-light($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ApercuProLight', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin apercu-pro-regular($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ApercuProRegular', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin austin-light($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'PublicoLight', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin austin-light-italic($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'PublicoLightItalic', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}