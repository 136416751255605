#search-page {
    background: #072542;
    padding-top: 110px;

    .search-head {
        background: #F7F8F8;
        padding: 100px 0;

        .column-12 {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .legend {
                flex: 0 0 auto;
                @include apercu-pro-bold(12px, 12px, 0.4em);
                text-transform: uppercase;
                color: #7F90A0;
            }

            .form {
                flex: 1 0 auto;
                margin-left: 20px;

                form {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    input[type='text'] {
                        @include apercu-pro-bold(40px, 68px);
                        height: 100%;
                        width: 100%;
                        color: $dark-blue;
                        padding-left: 10px;
                        border-bottom:  1px solid rgba(0, 25, 49, 0.08);

                        &::placeholder {
                            color: #AFB7BE;
                        }
                    }

                    input[type='submit'] {
                        position: absolute;
                        right: 0;
                        pointer-events: none;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .search-tabs {
        background: #EBEDEF;
        padding-top: 60px;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .no-result {
                padding-bottom: 60px;
                text-align: center;
                @include apercu-pro-bold(20px, 20px);
                width: 100%;
            }

            .tab {
                cursor: pointer;
                flex: 0 0 auto;
                @include apercu-pro-light(40px, 50px);
                color: $dark-blue;
                padding: 30px 60px;
                background-color: #DFE2E6;
                transition: background-color 0.6s $easeOutQuart;

                html.desktop & {
                    &:hover {
                        background-color: darken(#DFE2E6, 5%);
                    }
                }

                &.current {
                    background-color: white;
                    pointer-events: none;
                }
            }
        }
    }

    .search-content {
        background-color: white;

        .column-10 {
            display: none;
            @include set-grid-prop(margin-left, 1, 1);
            padding: 90px 0;

            &.current {
                display: block;
            }
        }

        ul {

            li {
                margin-top: 0;
                border-top: 1px solid rgba(0, 25, 49, 0.08);
                padding: 20px 0;

                &:last-child {
                    border-bottom: 1px solid rgba(0, 25, 49, 0.08);
                }

                &:before {
                    display: none;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    text-decoration-color: transparent !important;

                    .icon-pdf {
                        flex: 0 0 auto;
                        display: flex;
                        width: 30px;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: 1px solid $brown;
                        padding-left: 2px;
                        padding-bottom: 2px;
                        transition: background-color 0.6s $easeOutQuart;

                        svg {
                            width: 15px;
                            height: 15px;
                            fill: $brown;
                            transition: fill 0.6s $easeOutQuart;
                        }
                    }

                    .icon-download {
                        flex: 0 0 auto;
                        margin-left: auto;

                        svg {
                            width: 18px;
                            height: 16px;
                            fill: $dark-blue;
                            transition: fill 0.6s $easeOutQuart;
                        }
                    }

                    .metas {
                        margin-left: 20px;
                        padding-right: 40px;

                        .date {
                            @include apercu-pro-bold(12px, 12px);
                            color: $dark-blue;
                            opacity: 0.4;
                            transition: color 0.6s $easeOutQuart;
                        }

                        .name {
                            @include apercu-pro-bold(18px, 32px);
                            color: $text-color;
                            transition: color 0.6s $easeOutQuart;
                        }
                    }

                    html.desktop & {

                        &:hover {

                            .icon-pdf {
                                background-color: $brown;

                                svg {
                                    fill: white;
                                }
                            }

                            .metas {
                                .date {
                                    color: $brown;
                                }

                                .name {
                                    color: $brown;
                                }
                            }

                            .icon-download {
                                svg {
                                    fill: $brown;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .search-head {
            padding: 40px 0;

            .column-12 {
                flex-direction: column;
                align-items: flex-start;

                .form {
                    margin-left: 0;
                    margin-top: 10px;

                    form input[type=text] {
                        font-size: 30px;
                    }
                }
            }
        }

        .search-tabs {

            .column-10 {

                .tab {
                    padding: 20px;
                    font-size: 20px;
                    line-height: 1;
                }
            }
        }
    }

}