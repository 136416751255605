html {
    background-color: white;
    transition: opacity 0.8s $easeOutQuart;
}

body {
    position: relative;
    background-color: white;
    font-size: 100%;
    min-height: 100%;
    @include apercu-pro-regular(18px, 32px);
    color: $text-color;

    .gUhjIh {
        padding: 14px 12px 9px 12px!important;
    }
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

.page-template {
    padding-top: 36px;

    &:before {
        content: "";
        background-color: $dark-blue;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
    }

    &.home {
        padding-top: 0;
    }
}

svg,
img {
   display: block;
}

.display__block {
    display: block;
 }

#error-page {
    background: #072542;
    padding-top: 148px;

    .content {
        background-color: white;
        padding: 100px 0;
    }

    .column-12 {
        text-align: center;
    }
}

/**
OVERLAY
 */
.overlay {
    @include autoAlpha(0);
    @include full-fixed;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay__inner {
        background-color: white;
        padding: 4%;
        max-width: 80vw;
        max-height: 80vh;
        overflow: hidden;

        .text-content {

            h2 {
                text-align: center;
                font-size: 24px;
            }
        }

        .buttons {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: $mobile) {

    .page-template {
        padding-top: 0;
    }

    .overlay {

        .overlay__inner {
            overflow-y: scroll;
            padding-bottom: 40px;

            .buttons {
                flex-direction: column;

                .button-line {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }

}