.module-verbatim {
    background-color: $grey;
    padding-bottom: 100px;

    .background-text {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: -90px;
        opacity: 0.3;
        @include austin-light(480px);
        line-height: 0.5;
        width: 200%;
        color: $grey;
        white-space: nowrap;
        transform: translate(0, -110%);
        text-shadow:
                -1px -1px 0 $brown,
                1px -1px 0 $brown,
                -1px 1px 0 $brown,
                1px 1px 0 $brown;
    }

    .grid {
        z-index: 2;
    }

    .r-1 {
        text-align: center;
    }

    .r-2 {
        margin-top: 70px;
        display: flex;
        align-items: stretch;
        justify-content: center;

        .column-5 {
            float: none;
            box-shadow: 0 12px 24px rgba(0, 25, 49, 0.2);
            @include set-grid-prop(margin-left, 1, 0);
            background-color: white;
            padding: 7.6% 3.8% 7.6% 3.8%;
            text-align: center;

            &:first-child {
                margin-left: 0;
            }

            .partial.quote {
                margin-top: 0;
            }

            .partial.quote svg.open-quote-icon {
                left: 50%;
                transform: translate(-50%, -200%);
            }

            .partial.quote svg.close-quote-icon {
                left: 50%;
                transform: translate(-50%, 200%);
            }

            .partial.quote .quote__author {
                justify-content: center;
                margin-top: 80px;
            }

            .partial.quote .quote__author .quote__author-metas {
                width: auto;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-bottom: 60px;

        .background-text {
            display: none;
        }

        .r-2 {
            margin-top: 40px;
            flex-direction: column;

            .column-5 {
                padding: 100px 20px 50px 20px;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .partial.quote .quote__author {
            flex-direction: row;
        }

        .partial.quote .quote__author .quote__author-metas {
            margin-top: 0;
            margin-left: 20px;
            text-align: left;
        }
    }
}