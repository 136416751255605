.module-introduction {

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
        @include apercu-pro-light(26px, 44px);

        body.single & {
            @include set-grid-prop(margin-left, 2, 2);
            @include set-grid-prop(width, 7, 8);
        }

    }

    @media screen and (max-width: $mobile) {
        .column-10 {
            font-size: 24px;
            line-height: 38px;
        }
    }
}