/**
MODULE
 */
.module {
    position: relative;
    padding-top: 100px;

    &.with-margin-top-1 {
        margin-top: 100px;
    }

    &.no-padding-1 {
        padding-top: 0;
    }

    &.add-bottom-padding-1 {
        margin-bottom: 100px;
    }

    @media screen and (max-width: $mobile) {
        padding-top: 60px;

        &.with-margin-top {
            margin-top: 60px;
        }

        &.add-bottom-padding-1 {
            margin-bottom: 60px;
        }
    }
}

/**
VIDEO
 */
.video-thumbnail {
    height: 490px;
    width: 100%;

    a {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;

        &:after {
            content: "";
            background-color: $dark-blue;
            opacity: 0.3;
            @include full-absolute;
            z-index: 2;
            transition: opacity 0.6s $easeOutQuart;
        }

        .button-video {
            @include center-y;
            left: 10%;
            z-index: 3;
        }

        html.desktop & {
            &:hover {
                &:after {
                    opacity: 0.1;
                }
            }
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

/**
SWIPER ARROWS
 */
.swiper-arrows {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;

    .nav {
        cursor: pointer;

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        svg {
            width: 16px;
            height: 17px;
            fill: $dark-blue;
            opacity: 0.5;
            transition: opacity 0.6s $easeOutQuart;
        }

        html.desktop & {
            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }

        &.prev {
            margin-top: -2px;
            svg {
                transform: rotate(-180deg);
            }
        }

        &.next {
            margin-left: 30px;
        }
    }

    &.white,
    .module.green &,
    .module.brown & {
        .nav {
            svg {
                fill: white;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .module-cover + .module {
        margin-top: 0 !important;
    }
}