/**
PARTIAL
 */
.partial {

}

/**
SUBTITLE
 */
.partial.subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .line {
        width: 10px;
        height: 1px;
        background-color: $text-color;
    }

    .text {
        margin-left: 10px;
        @include apercu-pro-bold(11px, 17px, 0.4em);
        text-transform: uppercase;
    }
}

/**
MODULE TITLE
 */
.partial.title {

    .column-11 {
        @include set-grid-prop(margin-left, 1, 1);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .line {
            width: 10px;
            height: 1px;
            background-color: $text-color;
        }

        .text {
            margin-left: 10px;
            @include apercu-pro-bold(11px, 17px, 0.4em);
            text-transform: uppercase;
        }
    }

    &.white {

        .column-11 {

            .line {
                background-color: white;
            }

            .text {
                color: white;
            }
        }
    }

    .module.brown & {

        .column-11 {

            .line {
                background-color: #E3D2B9;
            }

            .text {
                color: #E3D2B9;
            }
        }
    }

    .module.green & {

        .column-11 {

            .line {
                background-color: #AFE1E2;
            }

            .text {
                color: #AFE1E2;
            }
        }
    }
}

/**
QUOTE
 */
.partial.quote {
    margin-top: 80px;
    max-width: 420px;

    .module.blue &,
    .module.green & {
        svg {
            fill: white;
        }

        .quote__content {
            color: white;
        }

        .quote__author {
            .quote__author-metas {
                .name,
                .function {
                    color: white;
                }
            }
        }
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: auto;
        fill: #A7B0B9;

        &.open-quote-icon {
            transform: translate(0, -180%);
        }

        &.close-quote-icon {
            right: 0;
            bottom: 0;
            left: auto;
            top: auto;
            transform: translate(0, 100%);
        }
    }

    .quote__content {
        position: relative;
        @include apercu-pro-regular(18px, 32px);
        color: #A7B0B9;
    }

    .quote__author {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .quote__author-img {
            flex: 0 0 auto;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background-color: $grey;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .quote__author-metas {
            width: calc(100% - 85px);
            margin-left: 20px;

            .name {
                @include austin-light(25px, 25px, 0.03em);
                color: $dark-blue;
            }

            .function {
                @include apercu-pro-bold(12px, 18px, 0.4em);
                text-transform: uppercase;
                margin-top: 10px;
                color: $dark-blue;
            }
        }
    }

    @media screen and (max-width: $mobile){
        .quote__content {
            font-size: 16px;
            line-height: 28px;
        }

        .quote__author {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .quote__author-img {
                width: 48px;
                height: 48px;
            }

            .quote__author-metas {
                margin-top: 20px;
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

/**
DOCUMENTS
 */
.partial.documents {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 200;

    .documents__list {
        @include autoAlpha(0);
        position: absolute;
        background-color: white;
        box-shadow: 0 12px 24px rgba(0, 25, 49, 0.2);
        border-radius: 6px;
        bottom: calc(100% + 40px);
        transform: translate(0, 10px);
        right: 0;
        padding: 30px 30px 10px 30px;
        width: 324px;

        .title {
            @include apercu-pro-bold(11px, 11px, 0.4em);
            text-transform: uppercase;
            color: $brown;
        }

        .list {
            margin-top: 20px;

            ul {

                li {
                    border-top: 1px solid rgba(1, 18, 66, 0.1);

                    &:first-child {
                        border-top: none;
                    }
                }
            }

            a {
                position: relative;
                display: flex;
                @include apercu-pro-regular(16px, 24px);
                color: #5A6B7B;
                padding: 18px 0;
                transition: color 0.6s $easeOutQuart;

                &.img {
                    background-color: $grey;
                    padding-left: 10px;
                    padding-right: 60px;
                }

                html.desktop & {
                    &:hover {
                        color: $dark-blue;
                    }
                }

                img {
                    position: absolute;
                    right: 14px;
                    bottom: 0;
                    box-shadow: 3px 2px 11px rgba(0, 0, 0, 0.2);
                    width: 38px;
                    height: auto;
                }
            }
        }
    }

    .documents__open {
        cursor: pointer;
        padding: 0 20px;
        height: 60px;

        .documents__open-background {
            position: absolute;
            background-color: $brown;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 30px;
            box-shadow: 0 12px 24px rgba(67, 41, 0, 0.3);
            transition: background-color 1s $easeOutQuart, transform 0.6s $easeInOutQuart;
            z-index: 1;
        }

        .documents__open-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 2;

            svg {
                width: 18px;
                height: auto;
                fill: white;
            }

            .text {
                @include apercu-pro-regular(16px, 16px);
                color: white;
                margin-left: 14px;
            }
        }

        html.desktop & {
            &:hover {
                .documents__open-background {
                    background-color: darken($brown, 5%);
                    transform: scaleX(1.1);
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }
}