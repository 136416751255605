.module-big-boxes {

    .partial.title {
        + .module-content {
            margin-top: 30px;
        }
    }

    .module-content {

        .row {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;

            [class*='column'] {
                float: none;
            }
        }

        .box-item {
            flex: 0 0 auto;
            @include set-grid-prop(margin-left, 1, 0);
            @include set-grid-prop(margin-bottom, 1, 0);
            background-color: white;
            box-shadow: 0 12px 24px rgba(0, 25, 49, 0.12);
            padding: 40px;

            &:nth-child(3n + 1) {
                margin-left: 0;
            }

            .box-item__icon {
                position: relative;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .box-item__title {
                margin-top: 20px;
                @include apercu-pro-light(24px, 26px);
                color: $dark-blue;

                &:first-child {
                    margin-top: 0;
                }
            }

            .box-item__content {
                margin-top: 30px;
                @include apercu-pro-regular(16px, 28px);

                &:first-child {
                    margin-top: 0;
                }

                p {
                    margin-top: 14px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .module-content {

            .box-item {
                padding: 30px;
                margin-left: 0;
                margin-bottom: 0;
                margin-top: 30px;

                &:first-child {
                    margin-top: 0;
                }

                .box-item__title {
                    font-size: 22px;
                    line-height: 24px;
                }

                .box-item__content {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}