.module-accordion {

    .r-1 {

        .column-11 {
            @include set-grid-prop(margin-left, 1, 1);
            margin-bottom: 70px;
        }
    }

    .filter {
        margin-top: 90px;
        @include set-grid-prop(margin-left, 1, 1);
        display: none;
        align-items: center;
        justify-content: flex-start;

        body.press-portal &,
        body.espace-presse & {
            display: flex;
        }

        .legend {
            @include apercu-pro-bold(12px, 12px, 0.4em);
            text-transform: uppercase;
        }

        form {
            margin-left: 30px;
            flex: 1 0 auto;
            max-width: 220px;
        }

        .ss-main .ss-single-selected {
            border: none;
            border-top: 1px solid rgba(0, 25, 40, 0.08);
            height: 50px;

            .placeholder {
                @include apercu-pro-regular(16px, 16px, 0);
            }
        }

        .ss-content .ss-list .ss-option {
            @include apercu-pro-regular(16px, 24px, 0);
        }

    }

    .accordion-item {

        &:first-child {
            .accordion-item__head {
                border-top: none;
            }
        }

        &.active {
            .accordion-item__head {
                &:after {
                    opacity: 0;
                }
            }

            .accordion-item__content {
                height: auto;
            }
        }

        &.nb-0 {
            display: none;
        }

        .accordion-item__head {
            border-top: 1px solid rgba(0, 25, 49, 0.08);
            cursor: pointer;
            background-color: white;
            @include set-grid-prop(width, 9, 10);
            margin: 0 auto;
            height: 120px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            position: relative;
            @include apercu-pro-light(30px, 40px);
            color: $dark-blue;
            padding-right: 40px;

            &:before {
                /*display: none;
                opacity: 0;*/
                position: absolute;
                right: 0;
                bottom: 56px;
                content: "";
                background-color: $dark-blue;
                width: 20px;
                height: 2px;
            }

            &:after {
                /*display: none;
                opacity: 0;*/
                position: absolute;
                right: 9px;
                bottom: 47px;
                content: "";
                background-color: $dark-blue;
                width: 2px;
                height: 20px;
                transition: opacity 0.6s $easeOutQuart;
            }
        }

        .accordion-item__content {
            background-color: $grey;
            overflow: hidden;
            position: relative;
            height: 0;
        }

        .accordion-item__content-inner {
            padding: 75px 0;
            @include set-grid-prop(width, 9, 10);
            margin: 0 auto;
        }
    }

    @media screen and (max-width: $mobile) {

        .filter {
            margin-top: 0;
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 40px;

            form {
                margin-left: 0;
                margin-top: 20px;
                width: 200px;
                max-width: 200px;
            }
        }

        .accordion-item {

            .accordion-item__content-inner {
                padding: 60px 0;
            }

            .accordion-item__head {
                font-size: 30px;
                line-height: 40px;
                height: auto;
                padding: 50px 0;
                width: 100%;
            }

            &:first-child {
                .accordion-item__head {
                    padding-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .module-cover + .module-accordion {
        margin-top: 60px !important;
    }
}