.module-title-text {

    .title {
        @include set-grid-prop(margin-left, 1, 1);

        h2 {
            font-size: 44px;
            line-height: 54px;
            margin-bottom: 30px;
        }

        .button {
            margin-top: 20px;
        }

        .metas {
            margin-top: 30px;

            .metas__title {
                @include apercu-pro-bold(12px, 18px, 0.4em);
                text-transform: uppercase;
                color: $dark-blue;
            }

            .metas__urls {
                margin-top: 20px;

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        margin-left: 30px;
                        flex: 0 0 auto;

                        &.web {

                            a {
                                display: flex;
                                align-items: center;
                            }

                            .text {
                                @include apercu-pro-regular(16px, 16px);
                                color: $brown;
                                margin-left: 10px;
                                margin-top: 3px;
                            }
                        }

                        svg {
                            width: 16px;
                            height: auto;
                            fill: $brown;
                            transition: fill 0.6s $easeOutQuart;
                        }

                        &.twitter {
                            margin-top: 4px;
                        }

                        &.linkedin,
                        &.twitter {
                            svg {
                                width: 20px;
                            }
                        }

                        html.desktop & {
                            &:hover {
                                svg {
                                    fill: darken($brown, 10%);
                                }
                            }
                        }

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .content {
        @include set-grid-prop(margin-left, 2, 1);

        .percent {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .percent__number {
                position: relative;
                width: 180px;
                height: 180px;
                border: 1px solid rgba(0, 25, 49, 0.08);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;

                .text {
                    @include apercu-pro-light(50px);
                    line-height: 1;
                    color: $dark-blue;

                    sup {
                        @include apercu-pro-bold(12px, 12px);
                        color: $text-color;
                        bottom: 1px;
                        vertical-align: super;
                        text-decoration: none;
                    }
                }

                svg {
                    @include center-xy;
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    stroke-linecap: round;
                    stroke: $brown;
                    z-index: 2;
                }
            }

            .percent__legend {
                width: calc(100% - 180px);
                padding-left: 30px;
                @include apercu-pro-bold(11px, 18px, 0.4em);
                text-transform: uppercase;
            }
        }

        .text-content {
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: $mobile){

        .title {
            h2 {
                font-size: 32px;
                line-height: 44px;
            }
        }

        .content {
            margin-top: 60px;

            .percent {
                flex-direction: column;

                .percent__legend {
                    padding-left: 0;
                    text-align: center;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile){
    .module-cover + .module-title-text {
        padding-top: 100px;
    }
}