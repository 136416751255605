.module-key-numbers {
    $margin: get-grid-percent(1, 0);

    .module-content {
        margin-top: 60px;
    }

    .column-12 {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        &.length-1 {
            .key-item {
                flex: 2 1 auto;
                width: auto;

                .key-item__number {

                    .value {
                        font-size: 130px;
                    }

                    .indice {
                        font-size: 96px;
                        padding-bottom: 8px;
                    }
                }
            }
        }

        &.length-2 {
            .key-item {
                width: calc(50% - #{$margin / 2});

                .key-item__number {
                    .value {
                        font-size: 130px;
                    }

                    .indice {
                        font-size: 96px;
                        padding-bottom: 8px;
                    }
                }
            }
        }

        &.length-3 {
            .key-item:first-child {
                flex: 1 0 auto;

                .key-item__number {
                    .value {
                        font-size: 130px;
                    }

                    .indice {
                        font-size: 96px;
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }

    .key-item {
        height: 330px;
        flex: 0 0 auto;
        background-color: white;
        padding: 40px;
        color: $dark-blue;
        width: calc(25% - #{$margin});
        margin-bottom: $margin;
        margin-left: $margin;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-shadow: 0 12px 25px 0 rgba(0, 25, 49, 0.2);

        &:nth-child(4n + 1) {
            margin-left: 0;
        }

        .key-item__icon {
            position: relative;
            height: 40px;

            img {
                height: 100%;
                width: auto;
            }
        }

        .key-item__number {
            margin-top: auto;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            .value {
                @include apercu-pro-light();
                @include set-vw(font-size, 60px);
                line-height: 1;
                white-space: nowrap;
            }

            .indice {
                @include apercu-pro-light();
                @include set-vw(font-size, 40px);
                line-height: 1;
                margin-left: 1%;
                padding-bottom: 4px;
            }
        }

        .key-item__legend {
            @include apercu-pro-regular(16px, 24px);
            color: #536C80;
        }
    }

    @media screen and (max-width: $mobile) {
        .column-12 {
            flex-direction: column;

            .key-item {
                margin-bottom: 20px;
                margin-left: 0 !important;
                width: 100% !important;
                height: auto;
                padding: 30px 20px;

                .key-item__icon {
                    display: none;
                }

                .key-item__number {
                    margin-top: 0;

                    .value {
                        font-size: 56px !important;
                    }

                    .indice {
                        font-size: 38px !important;
                        padding-bottom: 4px !important;
                    }
                }
            }
        }
    }
}