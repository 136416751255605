.module-news {
    padding-bottom: 100px;
    overflow: hidden;

    &.brown {
        background-color: $brown;

        .text-content {
            color: #E3D2B9;
        }

        .tag {
            color: white;
        }

        .excerpt {
            color: #E3D2B9;
        }
    }

    &.green {
        background-color: $green;

        .background-text {
            color: $green;
        }

        .text-content {
            color: white;
        }

        .tag {
            color: white;
        }

        .excerpt {
            color: white;
        }
    }

    .background-text {
        position: absolute;
        top: 220px;
        left: -220px;
        opacity: 0.3;
        @include austin-light(880px);
        line-height: 0.5;
        width: 200%;
        color: $brown;
        white-space: nowrap;
        text-shadow:
                -1px -1px 0 #FFFFFF,
                1px -1px 0 #FFFFFF,
                -1px 1px 0 #FFFFFF,
                1px 1px 0 #FFFFFF;
    }

    .module-button {
        position: absolute;
        left: 0;
        top: 94px;
        width: 100%;

        .row {
            display: flex;
            justify-content: flex-end;
        }
    }

    .r-1 {

        .column-5 {
            @include set-grid-prop(margin-left, 1, 1);
            margin-top: 40px;

            .button {
                margin-top: 30px;
            }
        }

        .column-4 {
            @include set-grid-prop(margin-left, 2, 1);
            margin-top: 40px;
        }
    }

    .r-2 {
        margin-top: 60px;

        .swiper-arrows {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .swiper-container {
            @include set-grid-prop(margin-left, 1, 1);
            overflow: visible !important;
        }

        .swiper-slide {

            .thumbnail {
                position: relative;
                width: 100%;
                height: 290px;
                overflow: hidden;

                &:after {
                    content: "";
                    background-color: $dark-blue;
                    opacity: 0.2;
                    @include full-absolute;
                    z-index: 2;
                    transition: opacity 0.6s $easeOutQuart;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                html.desktop & {
                    &:hover {
                        &:after {
                            opacity: 0;
                        }
                    }
                }
            }

            .metas {
                margin-top: 20px;

                .tag {
                    @include apercu-pro-bold(12px, 12px, 0.4em);
                    text-transform: uppercase;
                }

                .excerpt {
                    margin-top: 20px;
                    @include apercu-pro-regular(16px, 24px);
                }
            }
        }
    }

    @media screen and (max-width: $tablet){
        h2 {
            font-size: 60px;
        }
    }

    @media screen and (max-width: $mobile){
        display: flex;
        flex-direction: column;
        padding-bottom: 60px;

        .module-content {
            order: 2;
        }

        .module-button {
            order: 3;
            position: relative;
            left: auto;
            top: auto;
            margin-top: 40px;

            .row {
                justify-content: flex-start;
            }
        }

        .background-text {
            display: none;
        }

        h2 {
            font-size: 50px;
            line-height: 1;
        }

        .r-2 {
            margin-top: 40px;

            .swiper-slide {

                .thumbnail {
                    height: 160px;
                }
            }
        }
    }
}