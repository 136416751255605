.module-text {

    .column-8 {
        @include set-grid-prop(margin-left, 3, 3);
    }

    &.is-center-1 {

        .column-8 {
            float: none;
            position: relative;
            margin: 0 auto;
        }
    }
}

.module-video + .module-text {
    padding-top: 0;
    margin-top: 40px;
}