form {
    //margin-top: 30px;

    .input {
        border-bottom: 1px solid white;
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .button {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'] {
        @include apercu-pro-regular(16px, 24px);
        color: white;
        padding: 10px 0;

        &::placeholder {
            color: white;
            opacity: 0.5;
        }
    }
}