.module-new-and-cp {

    &.expanded {
        border-top: 1px solid rgba(0, 25, 49, 0.08);

        .column-8 {
            //@include set-grid-prop(width, 10, 11);
            //@include set-grid-prop(margin-left, 1, 1);
            @include set-grid-prop(width, 11, 12);
            margin-left: 0;

            .new-item {
                flex: 0 0 auto;
                border-right: 1px solid rgba(0, 25, 49, 0.08);
                //@include set-grid-prop(width, 3, 3);
                //padding: 50px 0;
                @include set-grid-prop(width, 4, 4);
                @include set-grid-prop(padding-left, 1, 1);
                padding-top: 80px;

                .excerpt {
                    max-width: 260px;
                }

                .tag {
                    white-space: normal;
                }
            }

            .cp-item {
                flex: 0 0 auto;
                @include set-grid-prop(width, 2, 3);
                @include set-grid-prop(margin-left, 1, 1);
                padding: 80px 5% 50px 0;

                &:last-child {
                    @include set-grid-prop(margin-left, 1, 0);
                }
            }

            .l-1 {
                border-right: 1px solid rgba(0, 25, 49, 0.08);
                @include set-grid-prop(width, 3, 3);
                padding-left: 0;
            }

            .l-2 {
                @include set-grid-prop(margin-left, 1, 1);
                @include set-grid-prop(width, 6, 7);
                padding-left: 0;
            }

            .socials {
                display: none;
            }
        }
    }

    &.home-version {

        .column-8 {

            .new-item {
                order: 3;
                padding-right: 0;
                margin-left: 50px;
            }

            .cp-item {
                order: 1;
            }
        }
    }

    .column-8 {
        background-color: white;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        @include set-grid-prop(margin-left, 4, 4);

        .new-item {
            flex: 1 0 auto;
            width: 38.85%;
            padding: 50px 5%;
            display: flex;
            flex-direction: column;

            &.blue {
                background-color: $dark-blue;
                color: white;

                .tag {
                    color: white;
                }

                .button {
                    svg {
                        fill: white;
                    }

                    .text {
                        color: white;
                    }
                }
            }


        }

        .cp-item {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            padding: 50px 0 50px 5%;
            width: calc(61.15% / 2);
        }

        .tag {
            @include apercu-pro-bold(11px, 16px, 0.4em);
            text-transform: uppercase;
            color: $dark-blue;
            white-space: nowrap;

            @media screen and (max-width: 1240px){
                white-space: normal;
            }
        }

        .date {
            @include apercu-pro-bold(11px, 11px, 0.4em);
            opacity: 0.6;
            margin-top: 40px;
        }

        .excerpt {
            margin-top: 30px;
            @include apercu-pro-regular(16px, 28px);
            padding-bottom: 70px;
        }

        .button {
            margin-top: auto;

            a {
                .text {
                    white-space: nowrap;
                }
            }
        }

        .socials {
            margin-top: auto;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                li {
                    margin-left: 15px;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        display: block;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $brown;
                        transition: fill 0.6s $easeOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            svg {
                                fill: darken($brown, 5%);
                            }
                        }
                    }

                    &.twitter {
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile){

        .grid {
            width: 100%;
        }

        .column-8 {
            flex-direction: column;

            .cp-item,
            .new-item {
                width: 100% !important;
                padding: 50px 5% !important;
                border-right: none !important;
                margin-left: 0 !important;

                .excerpt {
                    padding-bottom: 40px;
                }
            }

            .cp-item {
                border-top: 1px solid rgba(0, 25, 49, 0.08);
            }
        }
    }
}