.module-leaders {
    padding-bottom: 80px;

    .row {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        [class*='column'] {
            float: none;
        }
    }

    .sidebar {
        @include set-grid-prop(width, 4, 4);
        background-color: $brown;
        padding: 60px 4.5% 100px 4.5%;
        color: white;

        h2 {
            color: white;
        }

        .subtitle {
            margin-top: 10px;
            @include apercu-pro-light(30px, 40px);
        }

        .sidebar__portraits {
            margin-top: 60px;
        }

        .portrait-item {
            margin-top: 50px;

            &:first-child {
                margin-top: 0;
            }

            .thumbnail {
                position: relative;
                width: 150px;
                height: 150px;
                overflow: hidden;
                border-radius: 50%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .metas {
                margin-top: 20px;

                .name {
                    @include austin-light(24px, 24px, 0.025em);
                }

                .function {
                    margin-top: 8px;
                    @include apercu-pro-bold(12px, 18px, 0.4em);
                    text-transform: uppercase;
                }
            }
        }
    }

    .column-8 {
        $padding: get-grid-percent(1, 1);
        position: relative;
        padding: 100px 0 100px $padding;
        transform: translate(0, 80px);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background-color: $grey;
        }
    }

    @media screen and (max-width: $mobile) {
        padding-bottom: 0;

        .grid {
            width: 100%;
        }

        .row {
            flex-direction: column;
        }

        .sidebar {
            width: 100%;
            padding: 60px 30px;

            .sidebar__portraits {
                margin-top: 40px;
            }

            .portrait-item {

                .thumbnail {
                    width: 100px;
                    height: 100px;
                }
            }
        }

        .column-8 {
            transform: translate(0, 0);
            padding: 60px 30px;
        }
    }
}