.module-video-text {

    .module-content {

        .r-1 {

            .column-11 {
                @include set-grid-prop(margin-left, 1, 1);
            }
        }

        .r-2 {
            margin-top: 60px;

            .column-5 {
                @include set-grid-prop(margin-left, 1, 1);

                .video-thumbnail {
                    height: 300px;
                }
            }

            .column-4 {
                @include set-grid-prop(margin-left, 2, 1);

                .button {
                    margin-top: 30px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile){
        .module-content {
            .grid {
                width: 100%;
            }

            .r-1 {
                width: calc(100% - 60px);
                margin-left: auto;
                margin-right: auto;
            }

            .r-2 {
                margin-top: 30px;

                .column-4 {
                    margin-top: 50px;
                    width: calc(100% - 60px) !important;
                    margin-left: auto !important;
                    margin-right: auto !important;
                }
            }
        }
    }
}

.ytp-impression-link {
    display: none !important;
}