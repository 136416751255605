.section-notations {
    background-color: $dark-blue;
    padding: 60px 0 50px 0;

    body.parent-pageid-8674 &,
    body.parent-pageid-15 & {
        display: none;
    }

    body.investisseurs-esg & {
        display: none;
    }

    a {
        display: block;
    }

    .mobile-link {
        margin-top: 40px;
        display: none;
    }

    .column-2 {
        @include set-grid-prop(margin-left, 1, 0);

        &:first-child {
            margin-left: 0;
        }

        &.c-3 {
            @include set-grid-prop(margin-left, 3, 2);
        }

        .logo {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            padding-bottom: 20px;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .notation-text-content {
            margin-top: 20px;

            p {
                margin-top: 14px;
                @include apercu-pro-regular(18px, 22px);
                color: white;

                &:first-child {
                    margin-top: 0;
                }

                strong {
                    @include apercu-pro-bold(12px, 22px, 0.4em);
                    color: rgba(255, 255, 255, 0.4);
                    text-transform: uppercase;
                }
            }
        }
    }

    .r-2 {
        margin-top: 40px;

        .c-2 {
            @include set-grid-prop(margin-left, 3, 2);
        }
    }
}

@media screen and (max-width: $mobile) {
    .section-notations {

        .mobile-link {
            display: block;
        }

        .r-1 {
             a:first-child {
                .column-2:first-child {
                    margin-top: 0;
                }
            }

            a:last-child {
                margin-top: 60px;
            }
        }

        .r-2 {
            display: none;
        }

        .column-2 {
            margin-top: 40px;

            .logo {
                height: auto;
            }
        }
    }
}