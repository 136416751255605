.module-timeline {
    padding-bottom: 100px;

    .partial.title,
    .module-content {
        position: relative;
        z-index: 2;
    }

    .module-background-color {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: $containerWidth * 1px;
        height: 100%;
        z-index: 1;

        &:before {
            position: absolute;
            top: 0;
            content: "";
            width: 200%;
            height: 100%;
            background-color: $grey;
            @include set-grid-prop(left, 8.5, 7);
        }
    }

    &.green {
        background-color: $green;
        color: white;

        .module-background-color {
            &:before {
                background-color: #007374;
            }
        }

        .partial.title {
            .column-11 {
                .line {
                    background-color: white;
                }
            }
        }

        h2 {
            color: white;
        }

        .text-content {
            color: white;
        }

        .module-content {

            .column-2 {

                .swiper-slide {
                    color: rgba(255, 255, 255, 0.6);

                    &:before {
                        background-color: rgba(255, 255, 255, 0.4);
                    }

                    &:after {
                        background-color: white;
                    }

                    &.active {
                        color: white;
                    }

                    html.desktop & {
                        &:hover {
                            color: white;
                        }
                    }
                }

            }

            .column-4 {

                .title {
                    color: white;

                    &:before,
                    &:after {
                        background-color: white;
                    }
                }

                .content {

                    .file {
                        border-top-color: rgba(255, 255, 255, 0.1);

                        a {
                            html.desktop & {
                                &:hover {
                                    .icon {
                                        background-color: white;

                                        svg {
                                            fill: $green;
                                        }
                                    }

                                    .text {
                                        color: white;
                                    }
                                }
                            }
                        }

                        .file__date {
                            color: white;
                            opacity: 0.6;
                        }

                        .file__name {
                            .icon {
                                border-color: white;

                                svg {
                                    fill: white;
                                }
                            }
                        }
                    }

                    .text-content {
                        color: #AFE1E2;
                    }
                }
            }

            .button-prev,
            .button-next {
                svg {
                    stroke: white;
                }
            }
        }
    }

    &.blue {
        background-color: $blue;
        color: white;

        .module-background-color {
            &:before {
                background-color: $dark-blue;
            }
        }

        .partial.title {
            .column-11 {
                .line {
                    background-color: white;
                }
            }
        }

        .module-content {

            .column-2 {

                .swiper-slide {
                    color: rgba(255, 255, 255, 0.6);

                    &:before {
                        background-color: rgba(255, 255, 255, 0.4);
                    }

                    &:after {
                        background-color: white;
                    }

                    &.active {
                        color: white;
                    }

                    html.desktop & {
                        &:hover {
                            color: white;
                        }
                    }
                }

            }

            .column-4 {

                .title {
                    color: white;

                    &:before,
                    &:after {
                        background-color: white;
                    }
                }

                .content {

                    .file {
                        border-top-color: rgba(255, 255, 255, 0.1);

                        a {
                            html.desktop & {
                                &:hover {
                                    .icon {
                                        background-color: white;

                                        svg {
                                            fill: $dark-blue;
                                        }
                                    }

                                    .text {
                                        color: white;
                                    }
                                }
                            }
                        }

                        .file__date {
                            color: white;
                            opacity: 0.6;
                        }

                        .file__name {
                            .icon {
                                border-color: white;

                                svg {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
            }

            .button-prev,
            .button-next {
                svg {
                    stroke: white;
                }
            }
        }

        h2 {
            color: white;
        }

        .text-content {
            color: white;
        }
    }

    .partial.title {
        + .module-content {
            margin-top: 30px;
        }
    }

    .module-content {

        .column-5 {
            @include set-grid-prop(margin-left, 1, 1);

            .text-content {
                margin-top: 50px;
                max-width: 420px;
            }
        }

        .column-2 {
            position: relative;
            @include set-grid-prop(margin-left, 1, 0);
            z-index: 1;

            .swiper-container {
                position: relative;
                height: 500px;
                width: 100%;
            }

            .swiper-slide {
                cursor: pointer;
                position: relative;
                @include apercu-pro-light(40px, 40px);
                color: #AFB7BE;
                padding-left: 30px;
                height: 100px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                transition: color 0.6s $easeOutQuart;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "";
                    width: 3px;
                    height: 100%;
                    background-color: rgba(0, 25, 49, 0.2);
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 3px;
                    background-color: $dark-blue;
                    height: 100%;
                    left: 0;
                    top: 0;
                    transform-origin: 0 0;
                    transform: scaleY(0);
                    transition: transform 0.6s $easeInOutQuart;
                }

                &.active {
                    color: $dark-blue;

                    &:after {
                        transform: scaleY(1);
                    }
                }

                html.desktop & {
                    &:hover {
                        color: $dark-blue;
                    }
                }
            }
        }

        .column-4 {
            position: relative;
            @include set-grid-prop(margin-left, 1, 0);
            z-index: 2;

            .swiper-item {
                display: none;

                &.active {
                    display: block;
                }
            }

            .number {
                @include apercu-pro-light(36px, 36px);
                opacity: 0.6;
                margin-bottom: 20px;
                color: #AFB7BE;
            }

            .icon {
                position: relative;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }

                + .title {
                    margin-top: 20px;
                }
            }

            .title {
                position: relative;
                @include apercu-pro-light(40px, 40px);
                color: $dark-blue;

                &:before {
                    display: none;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    bottom: 56px;
                    content: "";
                    background-color: $dark-blue;
                    width: 20px;
                    height: 2px;
                }

                &:after {
                    display: none;
                    opacity: 0;
                    position: absolute;
                    right: 9px;
                    bottom: 47px;
                    content: "";
                    background-color: $dark-blue;
                    width: 2px;
                    height: 20px;
                    transition: opacity 0.6s $easeOutQuart;
                }
            }

            .content {
                position: relative;
                overflow: hidden;
                padding-top: 40px;

                &:first-child {
                    padding-top: 0;
                }

                .content__inner {
                    //padding-top: 40px;
                }

                .text-content {
                    margin-top: 40px;

                    &:first-child {
                        margin-top: 0;
                    }

                    p,
                    ul,
                    ol {
                        font-size: 16px;
                        line-height: 28px;
                    }

                    img {
                        max-width: 100%;
                        height: auto;
                        margin: 0;
                    }
                }

                .file {
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: 1px solid rgba(0, 25, 49, 0.12);

                    &.no-date {
                        border-top: none;
                        padding-top: 0;
                    }

                    &:first-child {
                        padding-top: 0;
                        margin-top: 0;
                        border-top: none;
                    }

                    a {
                        display: block;

                        html.desktop & {
                            &:hover {
                                .icon {
                                    background-color: $dark-blue;

                                    svg {
                                        fill: white;
                                    }
                                }

                                .text {
                                    color: $dark-blue;
                                }
                            }
                        }
                    }

                    .file__date {
                        @include apercu-pro-bold(12px, 12px, 0.4em);
                        color: $text-color;
                        text-transform: uppercase;
                    }

                    .file__name {
                        margin-top: 20px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;

                        .icon {
                            margin-top: 6px;
                            flex: 0 0 auto;
                            display: flex;
                            width: 30px;
                            height: 30px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            border: 1px solid $dark-blue;
                            padding-left: 2px;
                            padding-bottom: 2px;
                            transition: background-color 0.6s $easeOutQuart;

                            svg {
                                width: 15px;
                                height: 15px;
                                fill: $dark-blue;
                                transition: fill 0.6s $easeOutQuart;
                            }
                        }

                        .text {
                            @include apercu-pro-regular(16px, 24px);
                            margin-left: 10px;
                            transition: color 0.6s $easeOutQuart;
                        }
                    }
                }
            }

            .button {
                margin-top: 40px;
            }
        }

        .button-prev,
        .button-next {
            position: relative;
            display: flex;
            padding-left: 30px;
            height: 50px;
            z-index: 10;
            cursor: pointer;

            svg {
                width: 20px;
                height: 16px;
                stroke: $dark-blue;
            }

            &.swiper-button-disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .button-prev {
            position: absolute;
            top: -50px;
            left: 0;

            svg {
                transform: rotate(-180deg);
            }
        }

        .button-next {
            align-items: flex-end;
        }
    }

    @media screen and (max-width: $tablet) {

        .module-background-color {
            &:before {
                left: 61%;
            }
        }

        .module-content {
            .column-2 {
                .swiper-slide {
                    font-size: 30px;
                    line-height: 1;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-bottom: 0;

        .module-background-color {
            display: none;
        }

        .module-content {

            .grid {
                width: 100%;
            }

            .column-5 {
                width: calc(100% - 60px) !important;
                margin-left: auto !important;
                margin-right: auto !important;
            }

            .column-2 {
                display: none;
            }

            .column-4 {
                margin-top: 50px;

                .swiper-item {
                    padding-top: 40px;
                    display: block;
                    border-top: 1px solid rgba(0, 25, 49, 0.08);

                    &:first-child {
                        margin-top: 0;
                        padding-top: 0;
                        border-top: none;
                    }

                    .content {
                        height: 0;
                        background-color: $grey;

                        .content__inner {
                            position: relative;
                            padding-bottom: 40px;
                        }
                    }

                    &.active {

                        .title {
                            &:after {
                                opacity: 0;
                            }
                        }

                        .content {
                            height: auto;
                        }
                    }
                }

                .icon {
                    display: none;
                }

                .number {
                    width: calc(100% - 60px);
                    margin-left: auto;
                    margin-right: auto;
                }

                .title {
                    padding-right: 30px;
                    padding-bottom: 40px;
                    width: calc(100% - 60px);
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 30px;
                    line-height: 1;

                    &:before {
                        display: block;
                        opacity: 1;
                    };

                    &:after {
                        display: block;
                        opacity: 1;
                    };
                }

                .content {

                    .content__inner {
                        width: calc(100% - 60px);
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        &.green {
            .column-4 .swiper-item.active .content {
                background-color: #007374;
            }

            .module-content .column-4 .swiper-item {
                border-color: rgba(255, 255, 255, 0.2);
            }
        }

        &.blue {
            .column-4 .swiper-item.active .content {
                background-color: $dark-blue;
            }

            .module-content .column-4 .swiper-item {
                border-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}